import { translate } from '../app/i18n';
import { routeConstants } from './route.constants';
import { ImageAssets } from '../assets/assetProvider';

export const categoryDefaultImage = ImageAssets.defaultThumbnail;

// BACKEND SORT PARAMETERS NO TRANSLATION NEEDED
export const postSort = {
  lowToHigh: `Price: Low to High`,
  highToLow: `Price: High to Low`,
  newlyAdded: `Newly Added`,
  sellerRating: `Seller Rating`,
  endingSoonest: `Ending Soonest`,
};

export const cardStyles = {
  STYLE_01: 'style_01', // Horizontal
  STYLE_02: 'style_02', // Vertical
  STYLE_05: 'style_05', // Product Info
  STYLE_DAY_DEAL: 'style_day_deal', // Day Deal
};

export const breakpoints = {
  mobile_max_width: `(max-width: 560px)`,
  mobile_min_width: `(min-width: 560px)`,
  tablet_max_width: `(max-width: 768px)`,
  tablet_min_width: `(min-width: 768px)`,
  tablet_lg_max_width: `(max-width: 1024px)`,
  tablet_lg_min_width: `(min-width: 1024px)`,
  desk_max_width: `(max-width: 1270px)`,
  desk_min_width: `(min-width: 1270px)`,
  desk_xl_max_width: `(max-width: 1640px)`,
  desk_xl_min_width: `(min-width: 1640px)`,
  full_max_width: `(max-width: 1920px)`,
  full_min_width: `(min-width: 1920px)`,
};

export const sliderBreakpoints = {
  100: {
    slidesPerView: 1,
    spaceBetween: 25,
  },
  500: {
    slidesPerView: 2,
    spaceBetween: 25,
  },
  1040: {
    slidesPerView: 3,
    spaceBetween: 25,
  },
  1440: {
    slidesPerView: 4,
    spaceBetween: 25,
  },
};

export const generalConstants = {
  UNSECURED: 'Unsecured',
  EGP_CURRENCY: 'EGP', // To be used for the analytics events ONLY
  FAV_CATEGORIES: 5,
  CARD_START_TIMER: 4200000, // 70 minutes
  THREE_MINUTES: 180000, // three minutes
  LAST_CHANCE_DURATION: 5000, // 5 seconds
  BOTH_CAPITAL_CASE: 'Both',
  PICKUP_CAPITAL_CASE: 'Pickup',
};

/**
 * @typedef {Object} MenuItem
 * @property {string} icon - Menu icon class name
 * @property {string} label - Menu label text
 * @property {*} value - Menu actionable data example: url string
 * @property {number} [badge] - Menu badge value
 * @property {'primary' | 'danger'} [badgeColor] - Menu badge color
 */
/**
 * Watchlist Menu Items
 * @type {{shoppingCart: MenuItem; wishList: MenuItem; notification: MenuItem}}
 */
export const watchlistMenuItems = {
  shoppingCart: {
    icon: 'icon-bag',
    label: translate('accountDropdown.shoppingCart'),
    value: routeConstants.COMING_SOON,
    badgeColor: 'primary',
  },
  wishList: {
    icon: 'icon-heart',
    label: translate('accountDropdown.watchlist'),
    value: routeConstants.WATCHLIST,
  },
  notification: {
    icon: 'icon-notification',
    label: translate('accountDropdown.notification'),
    value: routeConstants.COMING_SOON,
    badgeColor: 'danger',
  },
};

export const postListKeys = {
  CATEGORY: 'category',
  PRICE: 'price',
  SELLING_METHOD: 'sellingMethod',
  CONDITION: 'condition',
  SECURED: 'secured',
};

export const LANGUAGES = ['en', 'ar'];

export const PaginationParams = {
  page: 1,
  limit: 12,
};

/**
 * Get post list field keys
 * @param {'price' | 'category' | 'sellingMethod' | 'condition' | 'secured'} key
 * @param {'en' | 'ar'} language
 * @returns {string}
 */
//TODO : it is API and refactoring is required from backend
export const getPostListKeys = (key, language) => {
  switch (key) {
    case postListKeys.CATEGORY:
      return language === 'en' ? 'Category' : 'الفئة';
    case postListKeys.PRICE:
      return language === 'en' ? 'Price' : 'السعر';
    case postListKeys.SELLING_METHOD:
      return language === 'en' ? 'Selling Method' : 'طريقة البيع';
    case postListKeys.CONDITION:
      return language === 'en' ? 'Condition' : 'الحالة';
    case postListKeys.SECURED:
      return language === 'en' ? 'Secured By Mazadat' : 'مؤمن بواسطة مزادات';
    default:
      return '';
  }
};

export const getPostConditions = (condition) => {
  const conditions = {
    'New and sealed': translate('productConditions.newAndSealed'),
    'New with open box': translate('productConditions.newWithOpenBox'),
    Mint: translate('productConditions.mint'),
    Used: translate('productConditions.used'),
    Defective: translate('productConditions.defective'),
  };
  return conditions[condition];
};

export const imageTypes = {
  SVG: 'svg',
  WEBP: 'webp',
};

/**
 * Return image extension
 * @param {'svg' | 'webp'} type - image type
 * return {{imagesExtension: 'svg' | 'webp'}}
 */
export const getImageType = (type) => {
  switch (type) {
    case imageTypes.SVG:
    case imageTypes.WEBP:
      return { imagesExtension: type };
    default:
      return {};
  }
};

export const currencies = {
  EG: {
     en: 'EGP',
     ar: 'ج.م'
  },
  AE: {
     en: 'AED',
     ar: 'د.إ'
  },
  SA: {
     en: 'SAR',
     ar: 'ر.س'
  },
  OM: {
     en: 'OMR',
     ar: 'ر.ع'
  },
}
