import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { postAnalytics } from '../app/helper/general';
import { generalConstants } from '../constants';
import StorageService from '../services/StorageService';

const analytics = getAnalytics();
let userId = null;
if (!StorageService.get('user')) {
  userId = StorageService.get('fingerprint');
} else {
  userId = StorageService.get('user')?.id;
}

const eventNameEnum = {
  SIGN_UP: 'sign_up',
  LOGIN: 'login',
  SEARCH: 'search',
  VIEW_SEARCH_RESULTS: 'search_results',
  VIEW_ITEM: 'view_item',
  SELECT_ITEM: 'select_item',
  VIEW_ITEM_LIST: 'view_item_list'
}

// This function send the userId in every event automatically.

setUserId(analytics, userId);
// Capture the sign up event when the registration succeed.
const logSignUp = () => {
  window.fbq && window.fbq('track', 'CompleteRegistration');
  logEvent(analytics, eventNameEnum.SIGN_UP);
};

// Capture the login event when the login succeed.
const logLogin = () => {
  window.fbq && window.fbq('trackCustom', eventNameEnum.LOGIN);
  logEvent(analytics, eventNameEnum.LOGIN);
};

// Capture the search event when the user search for a keyword and takes the keyword as an argument.
const logSearch = (keyword) => {
  window.fbq && window.fbq('track', 'Search', { search_string: keyword });
  logEvent(analytics, eventNameEnum.SEARCH, { search_term: keyword });
};

// Capture the search results event when there are results for the keyword the user search for, takes the keyword as an argument.
const logSearchResults = (keyword) => {
  window.fbq && eventNameEnum.VIEW_SEARCH_RESULTS, { search_string: keyword };
  logEvent(analytics, eventNameEnum.VIEW_SEARCH_RESULTS, { search_term: keyword });
};
// Capture post view event when a user open post details page, takes response(post data), price and currency as an object of arguments.
const logPostView = (response) => {
  const postAnalytic = postAnalytics(response);
  const postViewParams = {
    items: postAnalytic,
    value: postAnalytic[0]?.price,
    currency: generalConstants.EGP_CURRENCY,
  };
  window.fbq && window.fbq('trackCustom', eventNameEnum.VIEW_ITEM, postViewParams);
  logEvent(analytics, eventNameEnum.VIEW_ITEM, postViewParams);
};

// Capture post select event when a user clicks on post card, takes post Id and post data as an object of arguments.
const logPostSelect = (id, post) => {
  const postSelectParams = { item_list_id: id, items: postAnalytics(post) };
  window.fbq && window.fbq('trackCustom', eventNameEnum.SELECT_ITEM, postSelectParams);
  logEvent(analytics, eventNameEnum.SELECT_ITEM, postSelectParams);
};

// Capture post list event when the post list page is used, takes category Id, page number and response(list data) as an object of arguments.
const logPostList = (page, id, response) => {
  const postListParams = {
    item_list_id: page ? page.toString() : '',
    item_list_name: id,
    items: postAnalytics(response),
  };
  window.fbq && window.fbq('trackCustom', eventNameEnum.VIEW_ITEM_LIST, postListParams);
  logEvent(analytics, eventNameEnum.VIEW_ITEM_LIST, postListParams);
};

const events = {
  logSignUp,
  logLogin,
  logSearch,
  logSearchResults,
  logPostView,
  logPostSelect,
  logPostList,
};

export default events;
