import styled from 'styled-components';

export const SectionWrapper = styled.div`
  padding: ${(props) =>
    props.lang === 'en' ? '60px 19.5% 60px 9%' : '60px 9% 60px 19.5%'};
  display: flex;
  gap: 70px;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    padding: ${(props) =>
      props.lang === 'en' ? '60px 12% 60px 9%' : '60px 9% 60px 12%'};
    gap: 35px;
  }
  @media screen and (max-width: 880px) {
    display: block;
    padding: 30px calc((100% - 470px) / 2);
  }
  @media screen and (max-width: 500px) {
    padding: 30px 20px;
  }
`;

export const ImgWrapper = styled.div`
  width: 60%;
  max-width: 825px;
  min-width: 500px;
`;
export const SectionImg = styled.img`
  width: 100%;
  aspect-ratio: 1.52; 
  height: 542px;
  margin: 40px 0;
  @media screen and (max-width: 880px) {
    display: none;
  }
`;
export const FormAndTitleWrapper = styled.div`
  display: block;
  width: 100%;
  max-width: 470px;
  min-width: 320px;
  height: fit-content;
`;

export const SectionTitle = styled.h1`
  font-size: ${(props) => props.theme.typography.FS_32};
  font-weight: bold;
  color: ${(props) => props.theme.colors.ON_SURFACE_HIGH};
  line-height: 1.5;
  text-align: start;
  @media screen and (max-width: 880px) {
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    font-size: ${(props) => props.theme.typography.FS_24};
  }
`;

export const SectionForm = styled.form`
  margin-top: 40px;
  @media screen and (max-width: 880px) {
    margin: ${props => props.contactus ? '30px 0 0' : '30px 0'};
  }
`;

export const MessageButton = styled.div`
  margin-top: 88px;
`
