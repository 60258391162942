import { useSelector } from 'react-redux';
import { PostCard } from '../../../organisms';
import PostHoc from '../../../higherOrderComponents/postHoc/postHoc';
import { translate } from '../../../../app/i18n';
import { CardSliderWrapper } from '../../../templates';
import { handleResponse, sectionsTitle } from '../../../../app/helper/general';
import { Link } from 'react-router-dom';
import { useHomeSectionQuery } from '../../../../hooks/useHomeQuery';
import { Divider } from '../../../atoms';
import { useMediaQuery } from 'react-responsive/src';
import { breakpoints, PaginationParams } from '../../../../constants/general';

export const HomeSections = ({
  closeOverlay,
  url,
  sectionObj,
  wrapperColor,
  title,
}) => {
  const { websiteLanguage, websiteCountry } = useSelector(
    (state) => state.user,
  );
  const { data: sectionsData, isLoading } = useHomeSectionQuery(
    url,
    PaginationParams,
  );
  const isMobile = useMediaQuery({ query: breakpoints.mobile_max_width });
  const isPortrait = useMediaQuery({ query: breakpoints.tablet_max_width });
  const postKey = sectionObj ? sectionObj.key : '';

  const Card = PostHoc(PostCard);

  const show =
    handleResponse(sectionsData).filter((post) => post.status === 'available')
      .length > 0;

  return show || isLoading ? (
    <div className={`home-sections ${wrapperColor}`}>
      <div className="home-sections_wrapper">
        <h1 className="home-sections_wrapper_header">
          {sectionsTitle(
            sectionObj.key,
            title[websiteLanguage],
            websiteCountry,
            websiteLanguage,
          )}
        </h1>
        <Link
          name={'browse section'}
          to={`/posts/${postKey}`}
          className="button-hyperlink button-hyperlink--borderd button-hyperlink--secondary"
        >
          {translate('postCardsSlider.browseAll')}
        </Link>
      </div>
      {isMobile && (
        <div className={'home-sections_divider'}>
          <Divider
            orientation={'horizontal'}
            spacing={'2px 0 15px'}
            thickness={1}
          />
        </div>
      )}
      <CardSliderWrapper
        data={handleResponse(sectionsData)}
        zerostateText={translate('postCardsSlider.zeroState')}
        isDataLoading={isLoading}
        showCardOnlyIfAvailable={true}
        spaceBetween={isMobile ? 20 : 30}
        itemWidth={isPortrait ? 270 : 240}
        swiperContainerClassName={'swiper-inner-wrapper'}
      >
        {
          <Card
            themeColor={'light'}
            closeOverlay={closeOverlay}
            isSmallCard={false}
          />
        }
      </CardSliderWrapper>
    </div>
  ) : null;
};
