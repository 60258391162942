import moment from 'moment';
import { translate } from '../i18n';
import { numify } from 'numify';
import { generalConstants } from '../../constants/general';

export const formateDiffTime = (date, format) => {
  /*
   * format is a boolean
   */
  return moment(date).fromNow(format);
};

export const formateNumberByDigit = (number, digit) => {
  let output = number + '';
  while (output.length < digit) {
    output = '0' + output;
  }
  return output;
};

export const formateSmallTimer = (date) => {
  let given = moment(date);
  let current = moment();
  let remainingMilliseconds = moment.duration(given.diff(current)).as('milliseconds');

  if (remainingMilliseconds === 0)
    return {
      text: translate('smallPostTimerComponent.expired'),
      key: 'expired',
      isCountDown: false,
    };
  else if (remainingMilliseconds <= generalConstants.CARD_START_TIMER )
    return {
      text: moment.utc(remainingMilliseconds).format('HH:mm:ss'),
      key: 'hourCountdown',
      isCountDown: true,
    };
  else {
    return {
      text: moment(date).fromNow(true),
      key: 'daysCountdown',
      isCountDown: false,
    };
  }
};
export const calcDiffTime = (date) => {
  let difference = moment.duration(calcDiffTimeByUnit(date));

  return {
    days: formateNumberByDigit(difference.days(), 2),
    hours: formateNumberByDigit(difference.hours(), 2),
    minutes: formateNumberByDigit(difference.minutes(), 2),
    seconds: formateNumberByDigit(difference.seconds(), 2),
  };
};

export const isExpired = (date) => {
  if (date) return moment(date).isBefore(moment());
};

export const calcDiffTimeByUnit = (date, unit) => {
  const now = moment();
  const incomingDate = moment(date);
  return incomingDate.diff(now, unit);
};

export const formatViews = (views) => {
  return numify(views);
};

export const formateDateByUnit = (date, unit) => {
  return moment(date).format(unit);
};

export const formatTime = (time) => {
  const timeByMoment = moment.utc(time, 'HH:mm');
  return moment(timeByMoment)?.local().format('hh:mm A');

};
