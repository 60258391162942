import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import config from '../../config';
import ar from './translations/ar';
import en from './translations/en';

const translationsJson = {
  en: { translation: en },
  ar: { translation: ar },
};

i18n
  // pass the react-i18next instance i18n.
  .use(initReactI18next)
  // detect user language
  // more info: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // more info: https://www.i18next.com/overview/configuration-options
  .init({
    resources: translationsJson,
    // setting detection options
    // more info: https://github.com/i18next/i18next-browser-languageDetector
    detection: {
      // order and from where user language should be detected
      order: [ 'querystring', 'localStorage' ],

      // keys or params to lookup language from
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'i18nextLng',

      // cache user language on
      caches: [ 'localStorage' ],
      excludeCacheFor: [ 'cimode' ], // languages to not persist (cookie, localStorage)
    },
    fallbackLng: config.DEFAULT_LOCALE,
  });

export default i18n;
