import { useEffect, useRef, useState } from 'react';
import { AddButton } from '../../atoms';
import { PopoverWrapper } from '../../templates';
import { AccountDropdown, MazadatValet, PostType } from '../../molecules';
import { translate } from '../../../app/i18n';
import { useNavigate } from 'react-router-dom';
import { routeConstants } from '../../../constants';
import { IconAssets, ImageAssets } from '../../../assets/assetProvider';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAvatarUrl } from '../../../app/helper/general';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { searchAction } from '../../../redux/actions/overlayAction';

export default function BottomMenu({ setFixedBottom }) {
  const { showSearch } = useSelector((state) => state.overlay);
  const [showCreatePostOptions, setShowCreatePostOptions] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showOverLay, setShowOverLay] = useState(false);
  const [avatar, setAvatar] = useState('');
  const ref = useRef();
  const data = useSelector((state) => state.user);
  const { isAuthenticated } = useSelector((state) => state.auth, shallowEqual);
  const dispatch = useDispatch();

  let pathname = window.location.pathname;
  const navigateTo = useNavigate();

  const onCreatePostHandler = () => {
    setShowCreatePostOptions(!showCreatePostOptions);
    setShowOverLay(!showOverLay);
  };

  const onHandleCloseOverlay = () => {
    dispatch(searchAction(false));
    setShowOverLay(false);
  };

  const handleSearch = () => {
    if (showSearch) {
      onHandleCloseOverlay();
    } else {
      dispatch(searchAction(true));
    }
  };

  useOutsideClick(ref, () => {
    setShowCreatePostOptions(false);
  });

  const handleCreatePost = () => {
    setShowCreatePostOptions(false);
    setShowOverLay(false);
    navigateTo(routeConstants.COMING_SOON);
  };

  useEffect(() => {
    if (data) {
      setAvatar(getAvatarUrl(data?.avatar));
    }
  }, [data]);

  useEffect(() => {
    if (!showCreatePostOptions) {
      setShowOverLay(false);
    }
  }, [showCreatePostOptions]);

  useEffect(() => {
    setFixedBottom(showSearch || showProfile || showCreatePostOptions);
  }, [showSearch, showProfile, showCreatePostOptions]);

  return (
    // @TODO: change this structure to a dynamic one when all pages are implemented.
    <>
      <div
        className={`bottom-menu ${
          pathname.includes('post') ? 'display-none' : ''
        }`}
        ref={ref}
      >
        <span
          className={`bottom-menu_item`}
          onClick={() => {
            navigateTo(routeConstants.HOME);
            onHandleCloseOverlay();
          }}
        >
          {routeConstants.HOME === pathname ? (
            <img src={IconAssets.homeIcon} alt="home" />
          ) : (
            <span className="icon-home_bar"></span>
          )}

          <span className="bottom-menu_item_text">
            {translate('bottomMenu.home')}
          </span>
        </span>
        <div
          className={`bottom-menu_item`}
          onClick={() => {
            navigateTo(routeConstants.CATEGORIES);
            onHandleCloseOverlay();
          }}
        >
          {routeConstants.CATEGORIES === pathname ? (
            <img src={IconAssets.categoriesIcon} alt="categories" />
          ) : (
            <span className="icon-dashboard"></span>
          )}

          <span className="bottom-menu_item_text">
            {translate('bottomMenu.categories')}
          </span>
        </div>
        <div
          className={`bottom-menu_item ${
            showCreatePostOptions ? 'btn-active' : ''
          }`}
          onClick={(e) => {
            e.stopPropagation();
            onHandleCloseOverlay();
          }}
        >
          <AddButton
            isClicked={showCreatePostOptions}
            onClick={onCreatePostHandler}
          />
          {showCreatePostOptions && (
            <div>
              <MazadatValet type={'mobile'} onClick={handleCreatePost} />
              <PopoverWrapper
                backgroundColor={'light'}
                onClick={onCreatePostHandler}
                position="bottom"
                setShow={setShowCreatePostOptions}
              >
                <PostType
                  icon={'icon-Bid'}
                  backgroundColor={`bg-secondary`}
                  title={translate('createPostButton.createAuctionTitle')}
                  subtitle={translate('createPostButton.createAuctionSubtitle')}
                  onClick={handleCreatePost}
                />
                <PostType
                  icon={'icon-bag'}
                  backgroundColor={'bg-primary'}
                  title={translate('createPostButton.createDirectSaleTitle')}
                  subtitle={translate(
                    'createPostButton.createDirectSaleSubtitle',
                  )}
                  onClick={handleCreatePost}
                />
              </PopoverWrapper>
            </div>
          )}
        </div>

        <div onClick={handleSearch} className="bottom-menu_item">
          {showSearch ? (
            <img src={IconAssets.searchIcon} alt="search" />
          ) : (
            <span className="icon-search"></span>
          )}
          <span className="bottom-menu_item_text">
            {translate('bottomMenu.search')}
          </span>
        </div>
        <div
          className="bottom-menu_item"
          onClick={() => {
            setShowProfile(!showProfile);
            onHandleCloseOverlay();
          }}
        >
          {isAuthenticated ? (
            <img
              className="login-user-avatar-mobile"
              src={avatar}
              onError={() => setAvatar(ImageAssets.userAvatar)}
              alt="avatar"
            />
          ) : (
            <span className="icon-profile"></span>
          )}
          <span className="bottom-menu_item_text">
            {translate('bottomMenu.profile')}
          </span>
          {showProfile && (
            <div className="profile-menu">
              <PopoverWrapper
                show={showProfile}
                position={'bottom'}
                setShow={setShowProfile}
              >
                <AccountDropdown />
              </PopoverWrapper>
            </div>
          )}
        </div>
      </div>

      {(showCreatePostOptions || showOverLay || showSearch || showProfile) && (
        <div
          onClick={onHandleCloseOverlay}
          className="cardProducts-overlay"
        ></div>
      )}
    </>
  );
}
