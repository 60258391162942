import { Icon, Label, LeftSide, Root } from './DropDownListItem.styled';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IconAssets } from '../../../assets/assetProvider';

/**
 * Drop Down Item
 * @param {string} label - Item label
 * @param {() => void} onClick - Click event
 * @param {JSX.Element} [left] - Left Component
 * @param {boolean} [active] - Active state
 * @param {boolean} showArrow - Right Arrow
 * @returns {JSX.Element}
 * @constructor
 */
function DropDownListItem({ label, onClick, left, active, showArrow }) {
  const { websiteLanguage } = useSelector((state) => state.user);

  return (
    <Root onClick={onClick}>
      <LeftSide>
        {left}
        <Label lang={websiteLanguage}>{label}</Label>
      </LeftSide>
      {showArrow ? (
        <Icon
          className={
            websiteLanguage === `en` ? `icon-arrow-right` : `icon-arrow-left`
          }
        />
      ) : (
        active && (
          <img
            alt={`active`}
            src={IconAssets.greenCheckIcon}
            width={21}
            height={21}
          ></img>
        )
      )}
    </Root>
  );
}

DropDownListItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  left: PropTypes.node,
  active: PropTypes.bool,
  showArrow: PropTypes.bool,
};

DropDownListItem.defaultProps = {
  left: null,
  right: null,
  showArrow: false,
};

export default DropDownListItem;
