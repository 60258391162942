import * as Styles from './needHelp.styled';
import { contactInfo, routeConstants } from '../../../constants';
import { Button } from '../../atoms';
import { translate } from '../../../app/i18n';

export default function NeedHelp() {
  const route =
    window.location.pathname === routeConstants.CONTACT_US
      ? '#send-message'
      : `${routeConstants.CONTACT_US}/#send-message`;

  return (
    <Styles.SectionWrapper>
      <Styles.SectionDetails>
        <Styles.SectionTitle>
          {translate('getHelpComponent.title')}
        </Styles.SectionTitle>
        <Styles.SectionSubtitle>
          {translate('getHelpComponent.shareExcitement')}
        </Styles.SectionSubtitle>
        <Styles.BtnWrapper>
          <Styles.InfoBtn href="mailto:info@mazad.at">
            {contactInfo.contactEmail}
          </Styles.InfoBtn>
          <Styles.InquiryBtn href={route}>
            <Button ariaLabel="send inquiry" fullWidth={true}>
              {translate('getHelpComponent.sendInquiry')}
            </Button>
          </Styles.InquiryBtn>
        </Styles.BtnWrapper>
      </Styles.SectionDetails>
    </Styles.SectionWrapper>
  );
}
