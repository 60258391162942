import { HttpClient } from './HttpClient';
import AuthService from './AuthService';
import StorageService from './StorageService';
import PostService from './postService';
import OrderService from './orderService';
import ReturnService from './returnService';
import InquiryService from './inquiryService';
import TransactionService from './transactionService';
import UserService from './userService';
import SearchService from './searchService';
import TopFiveCategoriesService from './topFiveCategoriesService';
import ActivePostsService from './activePostsService';
import HomeService from './homeService';
import HeaderPostCardsService from './headerPostCardsService';
import ContactFormService from './contactFormService';
import RegistrationService from './registrationService';
import AllCategoriesService from './allCategoriesService';
import AllServicePointsService from './allServicePoints';
import CategoryService from './categoryService';
import SellerProfile from './sellerProfileService';
import VerifyRecaptchaTokenService from './verifyRecaptchaToken';
import UpdateInstance from './updateInstanceService';

const initServices = () => {
  const httpClient = HttpClient();

  const storageService = StorageService;
  const authService = AuthService(httpClient, storageService);
  const postService = PostService(httpClient);
  const orderService = OrderService(httpClient);
  const returnService = ReturnService(httpClient);
  const inquiryService = InquiryService(httpClient);
  const transactionService = TransactionService(httpClient);
  const userService = UserService(httpClient);
  const searchService = SearchService(httpClient);
  const topFiveCategoriesService = TopFiveCategoriesService(httpClient);
  const activePosts = ActivePostsService(httpClient);
  const homeService = HomeService(httpClient);
  const headerPostCards = HeaderPostCardsService(httpClient);
  const contactForm = ContactFormService(httpClient);
  const registrationService = RegistrationService(httpClient);
  const allCategoriesService = AllCategoriesService(httpClient);
  const allServicePointsService = AllServicePointsService(httpClient);
  const categoryService = CategoryService(httpClient);
  const sellerProfile = SellerProfile(httpClient);
  const verifyRecaptchaTokenService = VerifyRecaptchaTokenService(httpClient);
  const updateInstanceService = UpdateInstance(httpClient);

  return {
    storageService,
    authService,
    postService,
    orderService,
    returnService,
    inquiryService,
    transactionService,
    userService,
    searchService,
    topFiveCategoriesService,
    activePosts,
    homeService,
    headerPostCards,
    contactForm,
    registrationService,
    allCategoriesService,
    allServicePointsService,
    categoryService,
    sellerProfile,
    verifyRecaptchaTokenService,
    updateInstanceService,
  };
};

export default initServices();
