import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    sharedLink: '',
    postExpiry : false,
};

export const postSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        loading: (state) => {
            return { ...state, isLoading: true };
        },
        postActionSuccess: (state, action) => {
            return {
                ...state,
                sharedLink: action.payload ? action.payload : '',
                isLoading: false,
            };
        },
        postActionFailure: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
        getPromotionOptionsSuccess: (state, action) => {
            return {
                ...state,
                promotionOptions: action.payload,
                isLoading: false,
            };
        },
        getPromotionOptionsFailure: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
        postExpiry : (state , action)=> {
            return {
                ...state,
                postExpiry : action.payload
            }
        },
        resetPost: () => {
            return {
                ...initialState
            }
        }
    },
});

export const {
    loading,
    postActionSuccess,
    postActionFailure,
    getPromotionOptionsSuccess,
    getPromotionOptionsFailure,
    resetPost,
    postExpiry
} = postSlice.actions;

export default postSlice.reducer;
