import config from '../../config';
import { generalConstants } from '../../constants';
import { translate } from '../i18n';
import moment from 'moment';
import { zones } from 'moment-timezone/data/meta/latest.json';
import { tz } from 'moment-timezone';
import { ImageAssets } from '../../assets/assetProvider';
import StorageService from '../../services/StorageService';
import { currencies, getPostListKeys, postListKeys } from '../../constants/general';
import { formatTime } from './formaters';

export const delay = (function () {
  let timer = 0;
  return function (callback, ms, that) {
    clearTimeout(timer);
    timer = setTimeout(callback.bind(that), ms);
  };
})();

export const convertLanguageName = (lan) => {
  // should be dynamic
  // TODO use i18n and default cannot be english. Default throws error
  switch (lan) {
    case 'ar':
      return 'arabic';
    default:
      return 'english';
  }
};
export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const checkUserPlatform = (type) => {
  if (type === 'newtab') {
    window.open(config.MAZADAT_APP_REDIRECTION, '_blank');
  } else {
    window.location.href = config.MAZADAT_APP_REDIRECTION;
  }
};
export const getImageUrl = (imageName, imageType) => {
  let url = '';
  if (imageName) {
    if (imageName.includes('http')) {
      url = imageName;
    } else {
      if (imageName.includes('firebase'))
        url = `${config.FIREBASE_IMG_URL}${imageName.replace(
          '/',
          '%2F',
        )}?alt=media`;
      else {
        if (imageType === 'thumbnail')
          url = `${config.THUMBNAIL_IMG_URL}${imageName}`;
        else if (imageType === 'image') url = `${config.IMG_URL}${imageName}`;
      }
    }
  }
  return url;
};
export const getAvatarUrl = (imageName) => {
  let url = ImageAssets.userAvatar;
  if (imageName) {
    if (imageName.includes('http')) {
      url = imageName;
    } else {
      url = config.AVATAR_IMG_URL + imageName;
    }
  }
  return url;
};

export const sortByDate = (array) => {
  return array.sort(function (a, b) {
    let dateA = new Date(a.createdAt).getTime();
    let dateB = new Date(b.createdAt).getTime();
    return dateA < dateB ? 1 : -1;
  });
};

export const sortReviewsByFilter = (array, sortBy) => {
  if (sortBy === 'newToOld') {
    return [...array].sort((a, b) =>
      moment(b.createdAt).isAfter(moment(a.createdAt)) ? 1 : -1,
    );
  } else if (sortBy === 'oldToNew') {
    return [...array].sort((a, b) =>
      moment(b.createdAt).isBefore(moment(a.createdAt)) ? 1 : -1,
    );
  } else if (sortBy === 'highToLow') {
    return [...array].sort(
      (a, b) => b.userRating.average - a.userRating.average,
    );
  } else if (sortBy === 'lowToHigh') {
    return [...array].sort(
      (a, b) => a.userRating.average - b.userRating.average,
    );
  }
};

export const replaceAllSpacesWithDashes = (str) => {
  return str
    ?.trim()
    .replace(/[,\s]+/g, '-')
    .toLowerCase();
};
export const lastElementToFirst = (arr) => {
  //special for array of arrays
  //[arr[1] , arr[2] , arr[3]] -> [arr[3] , arr[1] , arr[2]]
  arr.unshift(...arr.slice(-1));
  arr.pop();
  return arr;
};
export const acceptOnlyNumbers = (e) => {
  /*
    validation for apply numbers only
    tested in chrome , safari , firefox and Ios devices
  */
  e = e || window.event;
  let charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;
  let charStr = String.fromCharCode(charCode);
  if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
};

export const getBannerName = (lan, screenWidth, oneImg) => {
  let url = lan;
  if (oneImg === false) {
    if (screenWidth < 361) {
      url = `img_360_${lan}`;
    } else if (screenWidth < 641) {
      url = `img_640_${lan}`;
    } else if (screenWidth < 769) {
      url = `img_768_${lan}`;
    } else if (screenWidth < 1025) {
      url = `img_1024_${lan}`;
    } else if (screenWidth < 1367) {
      url = `img_1366_${lan}`;
    } else if (screenWidth < 1921) {
      url = `img_1920_${lan}`;
    } else url = `img_2560_${lan}`;
  }
  return url;
};

export const sectionsTitle = (key, title, country, lang) => {
  if (key === 'attractivePosts') {
    return `${title} 100  ${country !== null ? currencies[country][lang] : currencies.EG[lang]}`;
  } else return title;
};

export const handleResponse = (result) => {
  let response = [];

  if (result?.posts) {
    response = result.posts;
  }
  if (result?.data) {
    response = result.data.posts;
  }
  if (result?.servicePoints) {
    response = result.servicePoints;
  }
  if (Array.isArray(result)) {
    response = result;
  }
  if (result?.results) {
    response = result.results;
  }
  if (result?.runningPosts) {
    response = [
      ...result?.runningPosts,
      {
        ...result?.waitingStartPosts,

        waitingStartPost: true,
      },
    ];
  }
  return response || [];
};

export const isPostUnsecured = (post) => {
  return post === generalConstants.UNSECURED;
};

export const getDiscount = (post) => {
  if (post.discount) {
    return {
      unit: post.discount.discount_unit,
      value: post.discount.discount_value,
      oldPrice: post.price,
    };
  }
  return {};
};

export const getPostPrice = (post) => {
  let price = post.price ? post.price.toNumberFormat() : undefined;
  if (post.discount) {
    price = (post.price - post.discount.discount_amount).toNumberFormat();
  }
  return price;
};

export const getPostBiddingPrice = (post) => {
  if (post.auction) {
    return (
      post.auction.biddingCount > 0
        ? post.auction.bidIncrement + post.auction.lastBid.pricePerItem
        : post.auction.startPrice
        ? post.auction.bidIncrement + post.auction.startPrice
        : post.auction.bidIncrement
    ).toNumberFormat();
  }
  return undefined;
};

export const categoryPathToRoot = (pathToRoot, language) => {
  const ConvertedLanguage = convertLanguageName(language);
  return [...pathToRoot].reverse().map((item, index, arr) => ({
    label: item.name[ConvertedLanguage],
    route:
      index < arr.length - 1
        ? `/category/${replaceAllSpacesWithDashes(item.name.english)}/${
            typeof item.id === 'string'
              ? item.id
              : [...item.id].reverse()[index]
          }`
        : ``,
  }));
};

export const searchFilterFields = (filters, keyword) => {
  if (!keyword) {
    return filters;
  }
  const output = {};
  Object.keys(filters).forEach((key) => {
    const val = filters[key];
    if (key.toLowerCase().includes(keyword.toLowerCase())) {
      output[key] = val;
    } else {
      switch (typeof val) {
        case 'object':
          if (
            val.filter((item) =>
              typeof item === 'string'
                ? item.toLowerCase().includes(keyword.toLowerCase())
                : false,
            ).length
          ) {
            output[key] = val;
          }
          break;
      }
    }
  });
  return output;
};

export const postRoute = (title, id) => {
  return `/post/${replaceAllSpacesWithDashes(title)}/${id}`;
};
export const existDynamicSections = (sections, key = '') => {
  if (key === 'header') {
    return sections?.filter((obj) => obj.exist && obj.key !== 'dayDeals');
  } else return sections?.filter((obj) => obj.exist);
};

export const getServicePointAddress = ({
  floor,
  apartment,
  building,
  street,
  district,
  city,
}) => {
  //   (building || apartment || floor), street, district, city
  const address = [];

  if (building || apartment || floor) {
    address.push(building || apartment || floor);
  }
  if (street) {
    address.push(street);
  }
  if (district) {
    address.push(district);
  }
  if (city) {
    address.push(city);
  }

  return address.join(', ');
};

export const getServicePointTimeSlot = (slots) =>
  slots.map((slot) => {
    const start = formatTime(slot.start);
    const end = formatTime(slot.end);
    return `${start} ${translate('filters.to').toLowerCase()} ${end}`;
  });

export const extractNumbersFromString = (str) => {
  const numberPattern = /\d+/g;
  const numbersArray = str.match(numberPattern);

  if (numbersArray && numbersArray.length > 0) {
    const number = parseInt(numbersArray[0], 10);
    return number;
  }

  return null;
};

const mapPostAnalytics = (post) => {
  return {
    item_id: post?._id,
    item_name: post?.title,
    item_category: post?.category?.name?.english || '',
    item_location_id: '',
    price: post?.price
      ? post?.price
      : post?.auction?.lastBid?.pricePerItem ||
        post?.auction?.startPrice ||
        post?.auctionData?.startPrice ||
        post?.auctionData?.bidIncrement,
  };
};

export const getSwiperItemWidth = (width, spacing, swiperSize) => {
  const cardCount = Math.round(swiperSize / (width + spacing)) || 1;
  return (
    (swiperSize - spacing * (cardCount > 1 ? cardCount - 1 : 1)) / cardCount
  );
};

export const postAnalytics = (data, isObject = false) => {
  if (Array.isArray(data)) {
    return data.map((item) => mapPostAnalytics(item?.post ? item.post : item));
  }
  const postObject = mapPostAnalytics(data?.post ? data.post : data);
  return isObject ? postObject : [postObject];
};
export const getDefaultCountryCode = () => {
  const timezone = tz.guess(true);
  return zones[timezone].countries[0];
};

export const HandleShippingMethodText = (buyerShippingMethod) => {
  switch (buyerShippingMethod) {
    case generalConstants.BOTH_CAPITAL_CASE:
    case generalConstants.PICKUP_CAPITAL_CASE:
      return {
        shippingMethodText: translate('postDetailsPage.pickupAvailable'),
        shippingMethodColor: 'SUCCESS',
      };
    default:
      return {
        shippingMethodText: translate('postDetailsPage.pickupNotAvailable'),
        shippingMethodColor: 'ERROR',
      };
  }
};
export const getWatchlistStatus = () => {
  const data = StorageService.get('watchlistStatus');
  return data !== null ? JSON.parse(data) : false;
};

export const noPostFilters = (categoryId, filters, fields, language) => {
  const _fields = { ...fields };
  const _filters = { ...filters };

  if (categoryId) {
    delete _fields[getPostListKeys(postListKeys.CATEGORY, language)];
    delete _filters[getPostListKeys(postListKeys.CATEGORY, language)];
  } else {
    delete _fields[getPostListKeys(postListKeys.SELLING_METHOD, language)];
    delete _fields[getPostListKeys(postListKeys.SECURED, language)];
    delete _filters[getPostListKeys(postListKeys.SELLING_METHOD, language)];
    delete _filters[getPostListKeys(postListKeys.SECURED, language)];
  }

  const noFields =
    Object.values(_fields).filter((field) => field !== undefined).length < 1;
  const noFilters =
    Object.values(_filters).filter((filter) => filter !== undefined).length < 1;
  return noFilters && noFields;
};

export const languageToSwitch = (active) => {
  return active === 'en' ? 'ar' : 'en';
};

/**
 * Optimize call-to-action link in banner
 * @param {string} search
 * @param {string} link
 * @returns {string | {search: string}}
 */
export const getBannerLink = (search, link) => {
  if (link.startsWith('?')) {
    const currentSearchParams = new URLSearchParams(search);
    const newSearchParams = new URLSearchParams(link);
    Array.from(newSearchParams.keys()).forEach((key) => {
      currentSearchParams.set(key, newSearchParams.get(key));
    });
    return { search: currentSearchParams.toString() };
  }
  return link;
};

export const inputLanguageDetector = (text) => {
  const arPattern = /[\u0600-\u06FF\u0750-\u077F]/;
  const enPattern = /^[a-z]+$/i
  let result = null;
  if (arPattern.test(text?.charAt(0))) {
    result = 'ar'
  } else if (enPattern.test(text?.charAt(0))) {
    result = 'en'
  }
  return result
}


/**
 * Serialize a Javascript Object to a URL safe string
 * @example The delimiters `:` = Key value separator `,` = Object items separator `|` = Array values separator
 * @example Serialization output: `{key1: ["value"], key2: ["value1", "value2"]}` => `key1:value,key2:value1|value2`
 * @param {Object} objectData - Javascript object to serialize
 * @returns {string}
 */
export const serializeObjectToURL = (objectData) => {
  return Object.keys(objectData)
    .map((key) => {
      if (objectData[key] === undefined) return null;

      return `${key}:${objectData[key].join('|')}`;
    })
    .filter((item) => !!item)
    .join(',');
};

/**
 * Parse a serialized URL safe string to a Javascript Object
 * @example The delimiters `:` = Key value separator `,` = Object items separator `|` = Array values separator
 * @example Parsing output: `key1:value,key2:value1|value2` => `{key1: ["value"], key2: ["value1", "value2"]}`
 * @param {string} URLString - Serialized URL safe string to parse
 * @returns {Object}
 */
export const parseURLToObject = (URLString) => {
  if (!URLString) return null;

  const data = {};
  URLString.split(',').forEach((keyValuePair) => {
    const [key, values] = keyValuePair.split(':');
    data[key] = values.split('|');
  });
  return data;
};
