import { useLocation, useNavigate } from 'react-router-dom';
import AccountDropdownItem from '../accountDropdownItem/accountDropdownItem';
import { translate } from '../../../app/i18n';
import { routeConstants } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { languageToSwitch } from '../../../app/helper/general';
import { logout } from '../../../redux/actions/authActions';
import services from '../../../services';
import { useQuery } from '../../../hooks/useQuery';

function AccountDropdown({ closeDropdown }) {
  const navigateTo = useNavigate();
  const { websiteLanguage } = useSelector((state) => state.user);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const user = services.storageService.get('user');
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const searchQuery = useQuery();

  const updateLanguage = (lang) => {
    searchQuery.set('lang', lang);
    location.assign(`${pathname}?${searchQuery.toString()}`);
  };

  const handleClick = async () => {
    if (isAuthenticated) {
      dispatch(logout(user.logoutToken));
      if (closeDropdown) closeDropdown();
    } else {
      navigateTo(routeConstants.LOGIN);
    }
  };

  return (
    <>
      {
        <AccountDropdownItem
          title={
            isAuthenticated
              ? translate('accountDropdown.logout')
              : translate('accountDropdown.login')
          }
          icon={isAuthenticated ? 'icon-logout' : 'icon-login'}
          onClick={() => handleClick()}
        />
      }
      <hr className="hr" />
      <AccountDropdownItem
        title={translate('language.name')}
        icon={'icon-language'}
        onClick={() => updateLanguage(languageToSwitch(websiteLanguage))}
      />
    </>
  );
}

export default AccountDropdown;
