import { API } from '../constants';

export default function RerurnService(httpClient) {
    const getTransactions = (queryObject) => {
        return httpClient.get(API.GET_TRANSACTIONS, queryObject);
    };

    const getTransactionFilters = () => {
        return httpClient.get(API.GET_TRANSACTION_FILTERS);
    };

    return { getTransactions, getTransactionFilters };
}