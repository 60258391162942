import { cloneElement, Fragment } from 'react';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';

export default function SlackSliderWrapper({ data, children, sliderSettings }) {
  const { websiteLanguage } = useSelector((state) => state.user);

  const settings = {
    ...sliderSettings,
    rtl: websiteLanguage === 'ar',
  };
  return (
    <Slider className="slack-custom" {...settings}>
      {data?.map((element, index) => {
        return (
          <Fragment key={index}>
            {cloneElement(children, { ...{ data: element, websiteLanguage } })}
          </Fragment>
        );
      })}
    </Slider>
  );
}
