/* eslint-disable import/no-anonymous-default-export */

const firebaseConfig = {
  apiKey: 'AIzaSyB7IO4SjHhim2M--cq23OpmiEs6936Pc6o',
  authDomain: 'mazadat-firebase.firebaseapp.com',
  databaseURL: 'https://mazadat-firebase.firebaseio.com',
  projectId: 'mazadat-firebase',
  storageBucket: 'mazadat-firebase.appspot.com',
  messagingSenderId: '83605811490',
  appId: '1:83605811490:web:ae58fc2531d189289d757b',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseConfigDev = {
  apiKey: 'AIzaSyD144O9xC4HEOWZi6go6KCW_8RIe29Zq_Q',
  authDomain: 'mazadat-firebase.firebaseapp.com',
  databaseURL: 'https://mazadat-firebase.firebaseio.com',
  projectId: 'mazadat-firebase',
  storageBucket: 'mazadat-firebase.appspot.com',
  messagingSenderId: '83605811490',
  appId: '1:83605811490:web:27d479e1cc33d6509d757b',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const getPathByEnv = (path) => {
  const isDev = process.env.REACT_APP_CLOUD_ENV === 'development';
  switch (path) {
    case 'IMG_URL':
      return isDev ? 'imagesdev/' : 'imagesprod/';
    case 'INQUIRIES_IMG_URL':
      return isDev ? 'question-answer-dev/' : 'question-answer-prod/';
    case 'THUMBNAIL_IMG_URL':
      return isDev ? 'thumbnailsdev/' : 'thumbnailsprod/';
    case 'FIREBASE':
      return isDev ? firebaseConfigDev : firebaseConfig;
    default:
      break;
  }
};

// TODO Replace png/svg by webP
export default {
  BASE_URL: process.env.REACT_APP_BACKEND_URL,
  API_URL: process.env.REACT_APP_BACKEND_URL + '/api',
  IMG_URL: process.env.REACT_APP_IMAGE_CONTAINER + getPathByEnv('IMG_URL'),
  IMG_CONTAINER_WITHOUT_ENV: process.env.REACT_APP_IMAGE_CONTAINER,
  INQUIRIES_IMG_URL:
    process.env.REACT_APP_IMAGE_CONTAINER + getPathByEnv('INQUIRIES_IMG_URL'),
  THUMBNAIL_IMG_URL:
    process.env.REACT_APP_IMAGE_CONTAINER + getPathByEnv('THUMBNAIL_IMG_URL'),
  AVATAR_IMG_URL: process.env.REACT_APP_IMAGE_CONTAINER + 'avatars/',

  // TODO Solve the error from Google
  // We detected that your site is not verifying reCAPTCHA solutions.This is required for the proper use of reCAPTCHA on your site.Please see our developer site for more information.
  // https://developers.google.com/recaptcha/docs/verify#api-request
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,

  GOOGLE_HREF: 'https://play.google.com/store/apps/details?id=at.mazad.app',
  APPLE_HREF: 'https://apple.co/2SHG7Tx',
  SOCKET_PATH: '/api/socket.io',
  CLOUD_ENV: process.env.REACT_APP_CLOUD_ENV,
  MAZADAT_APP_REDIRECTION: process.env.REACT_APP_MAZADAT_APP_REDIRECTION,

  DEFAULT_LOCALE: 'en',
  LOCALES: {
    ENGLISH: 'en',
    Arabic: 'ar',
  },
  FIREBASE_IMG_URL:
    'https://firebasestorage.googleapis.com/v0/b/mazadat-firebase.appspot.com/o/',
  FIREBASE: getPathByEnv('FIREBASE'),
  SLIDER_CARDS_NUMBER: 1,
  SMALL_SCREEN: 912, // TODO this is suspicious
  MOBILE_SCREEN: 560, // TODO this is suspicious
  B2C: process.env.REACT_APP_B2C
};
