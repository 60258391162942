import config from '../config';

// icons
import checkCircleIcon from './icons/checkCircle.svg';
import exportIcon from './icons/export.svg';
import filterActiveIcon from './icons/filter-active.svg';
import filterInactiveIcon from './icons/filter-inactive.svg';
import greenCheckIcon from './icons/greenCheck.svg';
import infoCircleIcon from './icons/info-circle.svg';
import messageRemoveIcon from './icons/message-remove@3x.svg';
import mobileSortIcon from './icons/mobile-sort.svg';
import noteRemoveIcon from './icons/note-remove@3x.svg';
import outlineSortIcon from './icons/outlineSort.svg';
import secureIcon from './icons/secure-icon.svg';
import sellerBuyerIcon from './icons/seller_buyer.svg';
import tickCircleIcon from './icons/tick-circle@2x.svg';
import timerStartBulkIcon from './icons/timer-start_bulk.svg';
import categoriesIcon from './icons/categories.svg';
import homeIcon from './icons/home.svg';
import messagesIcon from './icons/messages@2x.webp';
import searchIcon from './icons/search.svg';
import cookiesIcon from './icons/cookies-icon.svg';
import arFlagIcon from './icons/egFlag.svg';
import enFlagIcon from './icons/usFlag.svg';
import noReviewsIcon from './icons/no-reviews.svg';
import valetIcon from './icons/valet.svg';
// Generic images
import auctionIllustration from './images/Auction.svg';
import businessRegIllustration from './images/businessReg.svg';
import feesIllustration from './images/fees.svg';
import itemConditionIllustration from './images/itemCondition.svg';
import restrictedProductsIllustration from './images/restrictedProducts.svg';
import userAgreementIllustration from './images/userAgreement.svg';
import biddingHistoryIllustration from './images/bidding-history-zero-state.webp';
import underConstructionIllustration from './images/construction.webp';
import deliveryIllustration from './images/delivery.svg';
import deliveryServiceIllustration from './images/deliveryService.svg';
import downloadPopUpEnIllustration from './images/download-img-en.webp';
import downloadPopUpArIllustration from './images/download-img-ar.webp';
import emptySearchIllustration from './images/empty_search.svg';
import favoriteIllustration from './images/favorite.svg';
import inspectIllustration from './images/inspect.svg';
import notFoundIllustration from './images/notFound.webp';
import paymentCardIllustration from './images/Payment.svg';
import returnRequestIllustration from './images/returnRequest.svg';
import smileIllustration from './icons/smile.svg';
import secureIllustration from './images/secure.svg';
import sendMessageIllustration from './images/sendMessage.svg';
import servicePointsIllustration from './images/servicePoints.svg';
import watchlistIllustration from './images/watchlist.svg';
import userAvatar from './images/user-avatar.jpeg';
import pickupIllustration from './images/pickup.svg';

// about us
import innerSectionBg from './images/innerSection-BG.svg';
import valuesInclusivityIllustration from './images/mazadatValues-inclusivity.webp';
import valuesCollaborationIllustration from './images/mazadatValues-collaboration.webp';
import valuesInnovationIllustration from './images/mazadatValues-innovation.webp';
import valuesSecurityIllustration from './images/mazadatValues-security.webp';
import valuesSustainabilityIllustration from './images/mazadatValues-sustainability.webp';

// contact us
import letUsTalkBg from './images/letUsTalk.svg';
import servicePointEnIllustration from './images/servicePoint-en.svg';
import servicePointArIllustration from './images/servicePoint-ar.svg';
import weAreHere from './images/weAreHere.svg';

// forgetPassword
import otpPopupIllustration from './images/otp.webp';
import forgetPasswordMobileIllustration from './images/forget-password-mobile.webp';
import forgetPasswordIllustration from './images/forget-password.webp';
// how it works
import headerBg from './images/header-BG.svg';
import headerWavesBg from './images/header-waves-BG.webp';
import headerWavesMobileBg from './images/header-waves-BG_mobile.webp';
import innerSectionBg2 from './images/innerSection-BG_2.svg';

// Feedback
import lightOverlayIllustration from './images/light-overlay.svg';

// Numbers
import mazadatNumbers3Illustration from './images/mazadatNumbers-3.webp';
import mazadatNumbers5Illustration from './images/mazadatNumbers-5.webp';
import mazadatNumbers45Illustration from './images/mazadatNumbers-45.webp';
import mazadatNumbers48Illustration from './images/mazadatNumbers-48.webp';
import mazadatNumbers72Illustration from './images/mazadatNumbers-72.webp';
import mazadatNumbers100Illustration from './images/mazadatNumbers-100.webp';

// login
import loginMobileIllustration from './images/login-img-mobile.webp';
import loginIllustration from './images/login-img.webp';
// registration
import otpIllustration from './images/otp.svg';
import registrationMobileIllustration from './images/registration-mobile.svg';
import registrationIllustration from './images/registration.svg';
import resetPasswordMobileIllustration from './images/reset-password-mobile.webp';
import noActivePostIllustration from './images/no-active-post.svg';
import noSellerReviewsIllustration from './images/no-seller-reviews.svg';
import notVerifiedIcon from './icons/not-verify.svg';
import phoneIcon from './icons/phone-icon.svg';
import shareIcon from './icons/share-icon.svg';
import starIcon from './icons/star-icon.svg';
import verifyIcon from './icons/verify-icon.svg';
import arrowDropDownIcon from './icons/arrowDropDown.svg';

// Logos
import appStoreEnIcon from './logos/app-store-en.svg';
import appStoreArIcon from './logos/app-store-ar.svg';
import googlePlayEnIcon from './logos/google-play-en.svg';
import googlePlayArIcon from './logos/google-play-ar.svg';
import mazadatEnIcon from './logos/mazadat-en.svg';
import mazadatArIcon from './logos/mazadat-ar.svg';
import mazadatBlackEnIcon from './logos/mazadat-black-en.svg';
import mazadatBlackArIcon from './logos/mazadat-black-ar.svg';

import fawryLogo from './logos/fawry.png';
import masterCardLogo from './logos/mastercard.png';
import valuLogo from './logos/valu.png';
import visaLogo from './logos/visa.png';

const mapIllustration = `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/map.webp`;
// Default image for small images
const defaultThumbnail = `${config.THUMBNAIL_IMG_URL}default_image@2x.png`;
// Default image for large images
const defaultImage = `${config.IMG_URL}default_image@3x.png`;

// When the language is english, we display the arabic icon so the user can switch
// TODO enhance en and ar keys from i18n
const flagsSwitcherAssets = {
  en: arFlagIcon,
  ar: enFlagIcon,
};

const appStoreLogos = {
  en: appStoreEnIcon,
  ar: appStoreArIcon,
};
const googlePlayLogos = {
  en: googlePlayEnIcon,
  ar: googlePlayArIcon,
};
const mazadatLogos = {
  en: mazadatEnIcon,
  ar: mazadatArIcon,
};
const mazadatBlackLogos = {
  en: mazadatBlackEnIcon,
  ar: mazadatBlackArIcon,
};
const shopSmartIllustration = {
  en: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/shop-smart-en.webp`,
  ar: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/shop-smart-ar.webp`,
};
const paymentIllustration = {
  en: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/payment-en.webp`,
  ar: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/payment-ar.webp`,
};
const productsIllustration = {
  en: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/products-en.webp`,
  ar: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/products-ar.webp`,
};
const safetyIllustration = {
  en: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/safety-en.webp`,
  ar: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/safety-ar.webp`,
};
const onlineOfflineIllustration = {
  en: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/online-offline-en.webp`,
  ar: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/online-offline-ar.webp`,
};

const innovationIllustration = {
  en: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/innovation-en.webp`,
  ar: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/innovation-ar.webp`,
};
const downloadPopUpIllustration = {
  en: downloadPopUpEnIllustration,
  ar: downloadPopUpArIllustration,
};
const servicePointIllustration = {
  en: servicePointEnIllustration,
  ar: servicePointArIllustration,
};
const categoriesIllustration = {
  en: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/categories-en.webp`,
  ar: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/categories-ar.webp`,
};
const categoriesMobileIllustration = {
  en: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/categoriesMobile-en.webp`,
  ar: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/categoriesMobile-ar.webp`,
};
const howitWorksTitleIllustration = {
  en: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/howitworksheader-en.webp`,
  ar: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/howitworksheader-ar.webp`,
};
const howitWorksFooterIllustration = {
  en: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/howitworksfooter-en.webp`,
  ar: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/howitworksfooter-ar.webp`,
};
const howitWorksFooterMobileIllustration = {
  en: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/howitworksfooter-Mobile-en.webp`,
  ar: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/howitworksfooter-Mobile-ar.webp`,
};
/**
 * @name VideoAssets
 */
export const VideoAssets = {
  aboutUs: {
    aboutUsVideo360: {
      en: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/AboutUsEn-360.mp4`,
      ar: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/AboutUsAr-360.mp4`,
    },
    aboutUsVideo1140: {
      en: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/AboutUsEn-1140.mp4`,
      ar: `${config.IMG_CONTAINER_WITHOUT_ENV}landing-page/AboutUsAr-1140.mp4`,
    },
  },
};

/**
 * @name LogoAssets
 */
export const LogoAssets = {
  appStoreLogos,
  googlePlayLogos,
  mazadatLogos,
  mazadatBlackLogos,
  fawryLogo,
  masterCardLogo,
  valuLogo,
  visaLogo,
};

/**
 * @name ImageAssets
 */
export const ImageAssets = {
  auctionIllustration,
  businessRegIllustration,
  feesIllustration,
  itemConditionIllustration,
  restrictedProductsIllustration,
  userAgreementIllustration,
  biddingHistoryIllustration,
  underConstructionIllustration,
  deliveryIllustration,
  deliveryServiceIllustration,
  emptySearchIllustration,
  favoriteIllustration,
  inspectIllustration,
  notFoundIllustration,
  paymentCardIllustration,
  returnRequestIllustration,
  secureIllustration,
  sendMessageIllustration,
  servicePointsIllustration,
  watchlistIllustration,
  innerSectionBg,
  innovationIllustration,
  valuesCollaborationIllustration,
  valuesInclusivityIllustration,
  valuesInnovationIllustration,
  valuesSecurityIllustration,
  valuesSustainabilityIllustration,
  onlineOfflineIllustration,
  safetyIllustration,
  letUsTalkBg,
  weAreHere,
  otpPopupIllustration,
  forgetPasswordMobileIllustration,
  forgetPasswordIllustration,
  headerBg,
  headerWavesBg,
  headerWavesMobileBg,
  innerSectionBg2,
  mapIllustration,
  mazadatNumbers3Illustration,
  mazadatNumbers5Illustration,
  mazadatNumbers45Illustration,
  mazadatNumbers48Illustration,
  mazadatNumbers72Illustration,
  mazadatNumbers100Illustration,
  paymentIllustration,
  productsIllustration,
  smileIllustration,
  shopSmartIllustration,
  loginMobileIllustration,
  loginIllustration,
  otpIllustration,
  registrationMobileIllustration,
  registrationIllustration,
  resetPasswordMobileIllustration,
  noActivePostIllustration,
  noSellerReviewsIllustration,
  userAvatar,
  pickupIllustration,
  downloadPopUpIllustration,
  servicePointIllustration,
  categoriesIllustration,
  categoriesMobileIllustration,
  howitWorksTitleIllustration,
  howitWorksFooterIllustration,
  howitWorksFooterMobileIllustration,
  lightOverlayIllustration,
  defaultImage,
  defaultThumbnail,
};

/**
 * @name IconAssets
 */
export const IconAssets = {
  checkCircleIcon,
  cookiesIcon,
  exportIcon,
  filterActiveIcon,
  filterInactiveIcon,
  greenCheckIcon,
  infoCircleIcon,
  messageRemoveIcon,
  mobileSortIcon,
  noteRemoveIcon,
  outlineSortIcon,
  secureIcon,
  sellerBuyerIcon,
  tickCircleIcon,
  timerStartBulkIcon,
  categoriesIcon,
  homeIcon,
  messagesIcon,
  searchIcon,
  notVerifiedIcon,
  phoneIcon,
  shareIcon,
  starIcon,
  verifyIcon,
  noReviewsIcon,
  flagsSwitcherAssets,
  arrowDropDownIcon,
  valetIcon,
};

// Default not used to avoid importing all the assets all the time
export default {
  Icons: IconAssets,
  Images: ImageAssets,
  Logos: LogoAssets,
  Videos: VideoAssets,
};
