/* eslint-disable max-lines */
const en = {
  general: {
    ofText: 'of',
    outOf: 'Out of',
    comma: ',',
    currency: 'EGP',
  },
  language: {
    name: 'العربية',
  },
  pagination: {
    pageText: 'Page',
  },
  postActions: {
    done: 'Done!',
  },
  readMoreComponent: {
    showLess: 'show less',
    readMore: 'read more...',
  },
  postCardsSlider: {
    zeroState: 'No products to show',
    browseAll: 'Browse all',
  },
  productConditions: {
    newText: 'New',
    mint: 'Mint',
    used: 'Used',
    defective: 'Defective',
    defect: 'Defect.',
    newWithOpenBox: 'New with open box',
    newAndSealed: 'New and sealed',
  },
  loginPage: {
    login: 'Log in',
    loginTitle: (width, screenSize) => {
      if (width <= screenSize) {
        return 'Welcome Back!';
      } else {
        return 'Buy & sell securely & conveniently';
      }
    },
    username: 'Username',
    usernamePlaceholder: 'Enter your username',
    password: 'Password',
    passwordPlaceholder: 'Enter your password',
    forgetPassword: 'Forget Password?',
    signIn: 'Sign In',
    dontAccount: 'Don’t  have an account?',
    phone: 'Phone',
    phonePlaceholder: 'Enter your phone',
    loginByPhone: 'By phone',
    loginByUsername: 'By Username',
    signUp: 'Sign Up',
  },
  userFeedback: {
    watchlist: {
      addedSuccessfully: 'Added to Watchlist successfully.',
      removedSuccessfully: 'Removed from Watchlist successfully.',
    },
    httpErrorMsg: {
      connectionError: 'You are not connected to the internet',
      somethingWrong: 'Something went wrong',
    },
    categoriesPage: {
      addedSuccessfully: 'Added to Favorites Successfully.',
      removedSuccessfully: 'Removed from Favorites successfully.',
    },
    loginPage: {
      loginSuccessful: 'You’ve logged in successfully.',
      logoutSuccessfully: 'Logout Successfully.',
    },
    searchForm: {
      historyCleared: 'History Cleared Successfully',
    },
    otpForm: {
      successSubmission: 'You have successfully registered! Get the app now!',
    },
    registrationForm: {
      recaptchaError: 'Error verifying reCAPTCHA.',
    },
  },
  watchlistPage: {
    dayCount: (count) => {
      const number = count.toNumberFormat();
      return count > 1 ? `${number} Days` : `${number} Day`;
    },
    favouriteSP: 'Favorite Service Point',
    locationOnMaps: 'Location on maps',
    workingHours: 'Working Hours',
    viewOnMap: 'View on google map',
    holiday: 'Holiday',
    watchlist: 'Watchlist',
  },

  forgetPasswordForm: {
    title: 'Reset your password',
    info: 'Insert the mobile number associated with your account.',
    reset: 'Reset',
  },

  resetPasswordForm: {
    title: 'Set a New Password',
    info: 'Choose a strong password with characters and numbers',
    newPassword: 'New Password',
    newPasswordPlaceholder: 'Password must have 8+ characters',
    confirmPassword: 'Confirm Password',
    confirmPasswordPlaceholder: 'Re-enter your new password',
    resetPassword: 'Reset Password',
  },

  accountDropdown: {
    profile: 'Profile',
    watchlist: 'Watchlist',
    notification: 'Notifications',
    logout: 'Logout',
    login: 'Login',
    shoppingCart: 'Shopping Cart',
  },

  popUpsActions: {
    proceed: 'Proceed',
    cancel: 'Cancel',
  },
  legalPages: {
    top: 'TOP',
    topics: 'Topics',
    productPolicy: 'Product Policy',
    businessRegistration: 'Business User’s Registration Policy',
    userAgreement: 'User Agreement',
    itemCondition: 'Item Condition Guide',
    feesPolicy: 'Fees Policy',
    privacyPolicyTopics: {
      introduction: 'Introduction and Consent',
      topics: 'Topics Covered under this Privacy Policy',
      collection: 'Our Collection of Your Personal Information',
      purposes:
        'Purposes for Which Mazadat Collects, Stores, Uses and Processes Your Personal Information',
      update: 'Updating the Privacy Policy',
      personalInfo:
        'Accessing, Reviewing and Amending Your Personal Information',
      entities: 'Entities with whom We Will Share Your Personal Information',
      marketing: 'Marketing and Communication',
      cookies: 'Cookies',
      protect: 'Protecting Your Personal Information',
      general: 'General Provisions',
    },
    termsAndConditionsTopics: {
      introduction: 'Introduction and Acceptance',
      service: 'The Service',
      fees: 'Fees',
      wallet: 'My Mazadat Wallet',
      promotions: 'Paid Promotions Service',
      conditions: 'Listing/Posting Conditions and Use of the Mazadat Platform',
      obligations: 'Seller Obligations',
      buyandbid: 'Buying and Bidding',
      collectionpoints: 'Collection Points (Click and Collect)',
      userRepresentations: 'User Representations and Warranties',
      registration: 'Registration and Account Creation',
      userViolations:
        'User Violations and Suspension and Cancellation of Accounts',
      eligibility:
        'Eligibility for Membership and Capacity to Enter into the User Agreement',
      amendments: 'Amendments to this User Agreement',
      governingLaw: 'Governing Law',
      disputeResolutionForum: 'Dispute Resolution Forum',
      limitation: 'Limitation of Liability',
      warrantiesDisclaimer: 'Disclaimer of Warranties',
      customerSupport: 'Mazadat Customer Support and User Feedback Guidelines',
      indemnity: 'Indemnity',
      daydeal: 'Deal of the Day',
      licenses: 'Licenses',
      intellectualPropertyRights:
        'Intellectual Property Rights, Patents and Trademarks',
      generalProvisions: 'General Provisions',
    },
    productPolicyTopics: {
      introduction: 'Introduction and Consent',
      prohibitedProducts: 'Prohibited Products',
      restrictedProducts: 'Restricted Products',
      violation: 'Violation of this Prohibited and Restricted Products Policy',
    },
    businessRegistrationTopics: {
      registrationPolicy: 'Business User’s Registration Policy',
      businessEntity: 'Registration of business entity',
    },
    conditionGuideTopics: {
      conditionGuide: 'Item Condition Guide',
      categoriesAndDefinitions: 'Available Categories and Definitions',
    },
    feesPolicyTopics: {
      feesPolicy: 'Fees Policy',
      transactionFees: 'Transaction Fees',
      promotionsFees: 'Paid Promotions Fees',
      storageFees: 'Storage Fees',
    },
  },
  filters: {
    sortBy: 'Sort By',
    search: 'Search',
    filter: 'Filter',
    result: 'Result For',
    filterSearch: 'Search in filter',
    resetAllFilter: 'Reset all filter',
    reset: 'Reset',
    apply: 'Apply',
    fromText: 'From',
    to: 'To',
  },
  postListPage: {
    price: 'Price',
    category: 'Category',
    pricing: 'Pricing',
    zeroState: 'There is no result to show',
    noFilterResult: 'There are no results with Your filter choices',
    clearAll: 'Clear All',
    sellingMethod: 'Selling Method',
    sliderSectionTitle: 'Featured Posts',
    filterResult: (count) => {
      if (count === 1) return `1 Post Found`;
      if (count > 100) return `100+ Posts Found`;
      return `${count.toNumberFormat()} Posts Found`;
    },
    filterChipsSeeMore: 'See More',
    filterChipsSeeLess: 'See Less',
    sorting: {
      lowToHigh: `Price: Low to High`,
      highToLow: `Price: High to Low`,
      newlyAdded: `Newly Added`,
      sellerRating: `Seller Rating`,
      endingSoonest: `Ending Soon`,
    },
  },
  searchPage: {
    zeroState: `There is no result with your search`,
    resultFor: (keyword) => `Search Result For "${keyword}"`,
  },
  searchResult: (count) => {
    if (count === 1) return `1 Result`;
    if (count > 100) return `100+ Results`;
    return `${count.toNumberFormat()} Results`;
  },
  httpErrorMsg: {
    somethingWrong: 'Something went wrong',
    notFound: 'Not found',
    connectionError: 'You are not connected to the internet',
    unauthorized: 'Unauthorized',
    noRefreshToken: 'No refresh token',
    pleaseTryAgain: 'Internal server error, please try again later',
  },
  notFoundPage: {
    title: 'Ooops, This page has not been found',
    description:
      'We are really sorry, But the page you requested is missing.. Perhaps we can help you or back to the home page',
    backToHome: 'back to Home Page',
    contactUs: 'Contact Us',
  },
  reportAbuseComponent: {
    reportAbuse: 'Report Abuse', //needs clean up
  },
  createPostButton: {
    createAuctionTitle: 'Create Auction',
    createDirectSaleTitle: 'Create Direct Sale',
    createAuctionSubtitle: 'Highest market price',
    createDirectSaleSubtitle: 'Your own buy now price',
    mazadatValetText: 'Mazadat Valet',
    mazadatValetLabel: 'New',
  },
  smallPostTimerComponent: {
    ends: 'Ends ',
    inText: 'in',
    expired: 'Expired',
    timer3MinsRuleText: 'Last Chance',
  },
  postDetailsPage: {
    productDescription: 'Product Description',
    questionsAndAnswers: 'Questions & Answers',
    loadMore: 'Load More',
    aboutSeller: 'About Seller',
    securedBy: 'Secured by',
    dontMissOut: 'Don’t miss this!',
    willExpireIn: 'Will expires in',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Min',
    seconds: 'Sec',
    material: 'Material',
    buyNow: 'Buy Now',
    bidNow: 'Bid Now',
    buyFor: 'Buy For',
    youMadePurchase: 'You made a purchase of that item on',
    itemsAreRemaining: 'remaining items',
    yourBidIsLeading: 'Your bid is leading now!',
    noDescription: 'There is no description yet!',
    noQuestionsAndAnswers: 'There are no questions and answers yet',
    zeroState: 'No reviews yet!',
    loadMoreQuestions: 'Load more questions',
    itemsSoon: 'Items soon ',
    soldOut: 'sold out!',
    itemSoldOut: 'This post is sold out',
    thisPostNotAvailable: 'This post is not available',
    notSecured: 'Seller-Buyer Direct',
    securedItemMobileView: 'This item is Secured By Mazadat ',
    zoomImage: 'Roll over image to zoom in',
    pickupIs: 'Pickup is',
    deliverBy: 'Delivery by',
    pickupNotAvailable: 'not available',
    pickupAvailable: 'available',
    mazadat: 'Mazadat',
    reply: 'Reply',
    maxQtyExceededText: 'Max quantity exceeded!',
    differentCountryState: {
      title: 'Ooops, This post is not available in your country.',
      description:  'We are really sorry, But the post you just opened is not available in your country.. Perhaps we can help you or back to the home page',
    },
    brandInfoComponent: {
      category: 'Category',
      subCategory: 'Subcategory',
      warranty: 'Warranty',
      endTime: 'End Time',
    },
    actions: {
      postSimilarButton: 'Post Similar',
      shareButton: 'Share',
    },
    bid: (number) => {
      if (number === 1) {
        return `1 Bid`;
      } else {
        return `${number.toNumberFormat()} Bids`;
      }
    },
    viewAnswers: 'View answers',
    reviewZeroState: 'There are no reviews, yet!',
    viewReviews: 'View Reviews',
    tabToExpand: 'Tap to expand',
    addReview: 'Add Review',
    comments: 'Comments',
    totalComments: 'Total number of comments',
    viewReplies: (number) => {
      if (number === 1)
        return `View ${number.toNumberFormat()} Previous Reply...`;
      else return `View ${number.toNumberFormat()} Previous Replies...`;
    },
    hideReplies: 'Hide replies', //TODO
    whatAboutThose: 'What about those?',
    cantFetchSection: `Cannot Display Section Data`,
    backToHome: 'Back to Home',
    reportAProblem: 'Report a Problem',
    cancel: 'Cancel',
    viewAllItems: 'View all items',
  },
  widgets: {
    securedItem: 'This item is Secured By Mazadat',
    securedItemTooltip: 'Access Mazadat’s service points to complete your orders safely',
    notSecured: 'Seller-Buyer Direct',
    secure: '100% Secure',
    payments: 'Diverse Payment Methods',
    delivery: 'Product Delivery',
    auctionOrDirectSale: 'Auction or Direct Sale',
    servicePointsNetwork: 'Expandable Service Points Network',
    winWinComissions: 'Win-Win Comissions',
    digitalInvoice: 'Digital Invoice',
    activeItems: 'Active Posts From ',
    viewAllItems: 'View all items',
    secureDes:
      'With Mazadat, you’re subjected to zero risks of getting mugged, harassed, or threatened in any way.',
    deliveryDes:
      'With Mazadat, you can have your order deliverd to your place, anywhere, anytime.',
    paymentsDes:
      'You can choose to pay easily via Mazadat’s digital wallet, credit cards, in installments or by cash.',
    auctionOrDirectSaleDes:
      'On Mazadat you can choose to sell your items through an auction, a direct sale with a set price, or through a hybrid of the two forms.',
    servicePointsNetworkDes:
      'At Mazadat’s service points sellers & buyers can inspect the products ahead of completing the purchase without the need to get anxious about meeting or trusting strangers',
    winWinComissionsDes:
      'You pay our comission when you sell your products only. Create as many free posts, and when you sell you get paid.',
    digitalInvoiceDes:
      'With Mazadat, you can generate your digital invoices for your sold items, securely and conveniently.',
    learnMore: 'Learn More',
  },
  biddingHistorySidebar: {
    viewHistory: 'View History',
    biddingHistory: 'Bidding History',
    biddingPrice: 'Bidding price:',
    bids: 'Bids',
    bidders: 'Bidders',
    highestBid: 'Highest Bid',
    noBidsAvailable: 'No bids available',
    beTheFirstToBid: 'No bids yet! Be the first.',
    lastBid: 'Last Bid',
    nextBid: 'Next Bid',
    bidNow: 'Bid Now',
  },
  header: {
    shopByCategory: 'Shop by category',
    createPost: 'Create Post',
    becomeBusiness: 'Become a business?',
  },
  footer: {
    safePayments: 'You can pay via',
    allRights: 'All Rights Reserved.',
    mazadatInc: '©Mazadat SAE.',
    contactInfo: 'Contact information',
    customerService: 'Customer service',
    termsConditions: 'Terms & Conditions ',
    contactUs: 'Contact Us',
    privacyPolicy: 'Privacy Policy ',
    downloadApp: 'Download our app',
    mazadat: 'Mazadat',
    aboutUs: 'About us',
    howITWorks: 'How it works',
    becomeBusiness: 'Become a business?',
  },
  footerMobile: {
    downloadApp: 'Download our app',
  },
  sharePopup: {
    shareThisPost: 'Share this post',
    copyLink: 'Or copy link',
    copiedToClipboard: 'Copied to clipboard',
    copy: 'Copy',
  },
  aboutSellerView: {
    verifiedSeller: 'Verified Seller',
    viewProfile: 'View seller profile',
    totalRatingAsSeller: 'Total ratings as a seller',
    showReviews: 'View Reviews',
    hideReviews: 'Hide Reviews',
    reviewsIn: 'Reviews in the last ',
    soldItems: (number) => {
      if (number < 1) return `Member since`;
      else if (number > 1) return `items since`;
      else return 'item since';
    },
    sold: 'Sold',
    outOf: 'out of',
    chatWithSeller: 'Chat with the seller',
    loadMoreReview: 'Load More Reviews',
    addReview: 'Add Review',
    zeroState: 'No reviews yet!',
  },
  ratingView: {
    commitment: 'Commitment',
    communication: 'Communication',
    speedAndDelivery: 'Speed and Delivery',
    overallExperience: 'Overall Experience',
    totalRatingText: 'Total ratings',
    viewDetails: 'View details',
  },
  pagerButtonComponent: {
    // used in postCardSlider and postList
    next: 'Next',
    previous: 'Previous',
  },
  postCard: {
    qtyText: (quantity, bids, discount) => {
      const number = quantity.toNumberFormat();
      // ORDER MATTERS, CHANGE CAREFULLY
      if (discount === 'amount') return '';
      // Discount = undefined or percentage
      if (bids > 0 || !!discount) return `${number} Left`;
      // Bids = 0 and Discount = undefined
      if (quantity === 1) return `Only 1 item available`;
      // Quantity is greater than 1
      if (quantity > 1) return `${number} items available`;
      // Quantity = 0
      if (quantity === 0) return 'No items available';
      return '';
    },
    bidsNumberText: (bids, discount) => {
      if (discount === 'amount') return '';
      if (bids === 1) return `1 Bid`;
      if (bids > 1) return `${bids.toNumberFormat()} Bids`;
      return ``;
    },
    discountValuePrefix: 'Save',
    off: 'OFF',
    bidNow: 'Bid Now',
    buyNow: 'Buy Now',
    soldOut: 'Sold Out!',
    notAvailableViewComponent: {
      notAvailable: 'Not Available',
    },
  },
  homePage: {
    title: 'Stay tuned! The homepage will launch soon!',
    description: 'You can search for items or use the Menu to browse Mazadat',
    featuredPostsTitle: 'Featured Post',
    topCategoriesTitle: 'Top Categories',
    startingFromValue: '100 EGP',
    dayDeal: {
      title: 'Day Deal',
      dontMissOutWillExpiresIn: 'Don’t miss this! Post expires in',
      discoverMore: 'Discover more',
      // Todo
      qtyText: (value) => {
        if (value === 1) return `Only 1 item remaining`;
        if (value > 1) return `${value.toNumberFormat()} items remaining`;
        if (value === 0) return 'No items remaining';
        return '';
      },
    },
    comingSoon: {
      title: 'Stay tuned for great saving deals!',
      weWillNotifyIn: 'We’ll notify you in',
      downloadAppGetLatest: 'Download app & get the latest deals!',
    },
  },
  cookies: {
    siteCookies: 'Hey! This site uses cookies.',
    cookiesDescription:
      'We use cookies to make your experience better on this website.',
    acceptCookies: `Okie Cookie!`,
  },
  megaMenu: {
    close: 'Close',
  },
  downloadPopup: {
    content: 'Download our app to use this feature.',
    close: 'Close',
    downloadAppNow: 'Download Mazadat Now!',
  },
  sendMessageForm: {
    title: 'Reach out to us!',
    description: `We believe good conversations spark great ideas!`,
    fullName: 'First, middle & last name',
    nameErr: 'Name',
    subjErr: 'Subject',
    mobileErr: 'mobile number',
    emailAddress: 'Enter your e-mail address',
    emailAddressErr: 'e-mail address',
    mobileNumber: 'Enter your mobile number',
    subject: 'Enter your subject',
    message: 'Please type your message',
    submitMessage: 'Submit Message',
    closeNow: 'Close',
    thankYou: 'Thank You!',
    characters: 'Characters',
    successMessageDescription:
      'Your message is sent successfully. Our team will get back to you as soon as possible.',
  },
  contactUsPage: {
    weAreHere: {
      title: `Contact us!`,
      description: `Share with us your thoughts!`,
      headOffice: 'Head Office',
      nasrCity: 'Nasr City',
      nasrCityAddress: '17 Mostafa EL-Nahaas Nasr City, Cairo',
      phoneNumber: 'Phone Number',
      mobileNumber: 'Mobile number',
    },
    letUsTalk: {
      title: `Do you need further help?`,
      shareExcitement: 'Share your experience with us!',
      sendInquiry: 'Or send us your inquiry',
    },
    ourServicePoints: {
      title: 'Our Service Points',
      description: 'We’re always here for you!',
      viewMap: 'View Map',
      locationOnMaps: 'Location on maps',
      workingHours: 'Working Hours',
      viewOnMap: 'View on google map',
      holiday: 'Holiday',
    },
    contactFromHeart: {
      title: 'Stay in ouch!',
      description: `Stalk us!`,
    },
  },
  getHelpComponent: {
    // used in contact us , terms & conditions and privacy policy
    title: `Do you need further help?`,
    shareExcitement: 'Share your experience with us!',
    sendInquiry: 'Or send us your inquiry',
  },
  textFieldComponent: {
    characters: 'Characters',
    optionalLabel: 'Optional',
    password: 'Password',
  },
  phoneNumberComponent: {
    phoneNumber: 'Mobile number',
    code: 'Code',
    selectCode: 'Select code',
  },
  registrationForm: {
    joinUs: 'Join Us',
    byContinuing: (width, screenSize) => {
      if (width <= screenSize) {
        return 'By proceeding, you agree with mazadat';
      } else {
        return 'By continuing, you agree to our';
      }
    },
    termsOfService: (width, screenSize) => {
      if (width <= screenSize) {
        return 'Terms of service';
      } else {
        return 'Terms & Conditions';
      }
    },
    registeredBusiness: 'Registered Business',
    invitationCode: 'Invitation Code',
    verify: 'Verify',
    phoneNumber: 'Mobile number',
    code: 'Code',
    username: 'Username',
    enterUsername: 'Enter your username',
    password: 'Password',
    passwordCharacters: 'Enter your password',
    title: (width, screenSize) => {
      if (width <= screenSize) {
        return 'Let’s get started';
      } else {
        return 'If opportunity doesn’t knock, build a door.';
      }
    },
    successSubmission: 'You have successfully registered! Get the app now!',
    businessTooltip:
      'Register as a business user & benefit from Mazadat’s unique e-commerce experience to expand your business.',
    invitationCodeTooltip:
      'Add your invitation code here if you are invited by a user.',
    userNameCheckList: {
      usernameCharLength: '3 to 30 characters (letters or numbers).',
      noSpecialChar: 'No special characters at the start or the end.',
      noSpaces: 'No spaces.',
      noUpperCase: 'No (CAPS) uppercase.',
      lessThanSix: 'Maximum 5 numbers.',
    },
    invitationCodePlaceHolder: 'Enter invitation code',
    signUp: 'Sign Up',
    signIn: 'Sign In',
    charAnd: '&',
    codeError: 'Please select code',
    selectCode: 'Select code',
    alreadyAccount: 'Already have an account?',
    privacyPolicy: 'Privacy Policy ',
  },
  validationErrorMsg: {
    required: (name) => `${name} is required`,
    min: (name, min) => `${name} must be at least ${min} characters`,
    max: (name, max) => `${name} must be at most ${max} characters`,
    invalid: (name) => `Invalid ${name}!`,
    nameError: 'Username is not valid, please check the rules',
    confirmPassword: 'Password do not match',
  },
  otpForm: {
    title: 'Verify your mobile number.',
    enterTheCode: 'Please enter digits code',
    changeNumber: 'Change number',
    notReceiveCode: 'Didn’t receive the code?',
    resendIn: 'Resend in',
    resendButton: 'Resend',
    verify: 'Verify',
    enterTheCodeSentToEmail: 'Enter the code sent to your email',
    changeEmail: 'Change email',
    otpInfo: 'Enter the 4-digit code that was sent to your mobile:',
    whatsapp: 'Or via WhatsApp',
    successSubmission:
      'You have successfully registered! Get the app now and explore Mazadat’s features.',
    whatsappCodeSentSucess: 'Code sent successfully to whatsapp',
  },
  passwordStrength: {
    weakPassword: 'Weak Password!',
    averagePassword: 'Average Password!',
    greatPassword: 'Great Password!',
  },
  breadCrumb: {
    home: 'Home',
    editPost: 'Edit post',
    similarPost: 'Similar post',
    sharePost: 'Share post',
    addToWatchList: 'Add to watchlist',
    reportAbuse: 'Report a Problem',
    privacyPolicy: 'Privacy Policy',
    termsAndConditions: 'Terms & Conditions',
    productPolicy: 'Product Policy',
    businessRegistrationPolicy: 'Business User’s Registration Policy',
    feesPolicy: 'Fees Policy',
    aboutUs: 'About Us',
    howItWorks: 'How It Works',
    categories: 'Categories',
    sellerProfile: 'Seller Profile',
    comingSoon: 'Coming soon',
    notFound: 'Not Found',
    contactUs: 'Contact Us',
  },
  bottomMenu: {
    profile: 'Profile',
    search: 'Search',
    categories: 'Categories',
    home: 'Home',
  },
  searchView: {
    searchFieldPlaceHolder: `I'm shopping for...`,
  },
  aboutUsPage: {
    shoppingExperience: {
      title:
        'At Mazadat we create pathways for buyers & sellers towards a unique shopping experience',
      description: `We are the first auctioning and X2X marketplace platform in the Middle East and Africa, offering a unique shopping experience for its users; where individuals (C2C) and businesses of different sizes (B2C) can sell their items and products. Users can sell items in time-boxed auctions, fixed-price “buy-Now” listings, or a hybrid format, while buyers enjoy the combined variety of offerings in one place.`,
      scroll: 'scroll',
    },
    innerSections: {
      onlineAndOffline: 'Bridging the online & offline worlds',
      onlineAndOfflineDesc: `Bridging the online and offline worlds, Mazadat has innovated the unprecedented, decentralized physical service point network to be the epitome of a “trustless” model, where all parties; individuals, business owners, buyers, and sellers do not need to place their trust in the stranger/the second party. This unique model supports merchants and vendors, including minor and micro enterprises with several benefits, such as storage space, logistics, and quick-operation service requirements, opening them a channel of endless opportunities to upgrade their business and further promote their brands.`,
      innovation: `Building innovative & sustainable X2C channels`,
      innovationDesc: `  At Mazadat, we aspire to build a community for everyone through scalable e-commerce experiences,
      where everyone can grow and thrive. We are firm believers that little efforts can make a huge difference,
      thus we’ve been rooted since Mazadat’s early inception to promote the idea of re-commerce (buying and selling pre-owned products),
      encouraging buyers and sellers to extend the life of products to contribute to the healthier planet and a green economy.`,
      safety: `Offering convenience & safety for users`,
      safetyDesc: `We strive to improve people’s lives through Mazadat’s affordability of product services, paired with the convenience, and safety offered by the concept of its unique service points across the country, which offers a win-win situation for businesses of all sizes; as they can take advantage of a variety of value-added vendor support services in the areas of logistics, storage, and order fulfillment, and the buyers who can safely and anonymously inspect their purchases.`,
      safetyDescSub: `Offering a variety of digitized payment solutions, Mazadat promises its clients accessible, convenient purchases through its platform and convenient installment payment options.`,
    },
    mazadatValues: {
      sectionTitle: `Mazadat Values`,
      firstSlide: {
        title: 'Innovation',
        desc: 'Our invented business model provides our users with a unique retail experience. Our application system employs the latest technology in curating a hassle-free shopping experience that is only one click away. ',
      },
      secondSlide: {
        title: 'Security',
        desc: 'Our marketplace platform is all about security, safety and building trust in a trustless world. We provide an escrow service, which ensures satisfaction for both the buyer and the seller. ',
      },
      thirdSlide: {
        title: 'Inclusivity',
        desc: 'Every user can access our platform, regardless of their language, location, financial status, and lifestyle needs. Mazadat’s inclusivity and accessibility are best reflected in its multilingual structure, the multiple payment options, and the flexible shipping options to accommodate diverse needs.',
      },
      fourthSlide: {
        title: 'Sustainability',
        desc: 'We promote the idea of re-commerce; encouraging buyers and sellers to extend the life of pre-loved items. This contributes to the reduction of waste and conserves resources.',
      },
      fifthSlide: {
        title: 'Collaboration',
        desc: 'We aspire to build a community for everyone through a scalable e-commerce experience.Whether you are a student, a mom, or a vendor looking to expand your reach, we welcome you to join our community and collaborate with us.',
      },
    },
    ourServicesPoint: {
      title: `Our services points`,
      servicePoints: {
        heliopolis: 'Heliopolis',
        el_Mohandseen: `El Mohandseen`,
        nasrCity: 'Nasr City',
        maadi: 'Maadi',
      },
    },
    subscription: {
      title: `We would love to hear more about your business`,
    },
  },
  howItWorksPage: {
    header: {
      smartAndSafe: 'smart. safe.',
      convenient: 'convenient',
      bidBuyAndSell: 'bid, buy & sell',
      description: 'With one account on Mazadat, you can bid, buy and sell',
    },
    fiveProducts: {
      title: '5 Different product conditions',
      newText: 'New',
      mint: 'Mint',
      used: 'Used',
      defective: 'Defective',
      openBox: 'Open box',
    },
    checkOutMazadat: {
      title: `Checkout Mazadat’s listings across 35+ categories`,
    },
    forgetAboutScam: {
      title: 'Forget about scams! go Secured by Mazadat',
      description:
        'An expandable network of service points where you can buy & sell safely, conveniently & privately!',
    },
    payment: {
      title: `Diverse & safe payment options`,
    },
    mazadatInNumbers: {
      title: `Mazadat in numbers`,
      circles: {
        download: 'Seconds to download',
        auctions: 'Seconds to start an auction or a direct-sale post',
        dropOff: 'Hours is the allowed period to drop off or pick up your item',
        returnRequest: 'Hour escrow period to file a return request',
        sellerBuyer: 'Buyer-seller protection',
        conditions: 'Different product conditions',
        increasePosts:
          'Promotional plans to choose from to increase your post’s sales',
      },
    },
    whySecured: {
      cards: {
        pickup: 'Drop-off & Pick-up at the service point',
        inspect: 'Inspect before your pick up an item',
        deliveryService: 'Delivery & storage services are available.',
        returnRequest: '48-hour window to file a return request',
      },
      title: 'Why "Secured by Mazadat"',
    },
    shopSmart: {
      title: 'Shop smart everyday',
    },
  },
  categoriesPage: {
    favouriteCategories: 'Favourite Categories',
    allCategories: 'All Categories',
    searchPlaceholder: 'Search in categories',
    noFavouriteSelected: 'Press on the heart icon to add to your favorites',
  },
  sellerProfilePage: {
    showContact: 'Show Contact',
    shareProfile: 'Share Profile',
    reviews: 'Reviews',
    activePost: 'Active Post',
    sellerReviews: 'Seller Reviews',
    outOf: 'out of',
    reportAbuse: 'Report Abuse', //needs clean up
    viewReviews: 'View Reviews',
    zeroState: 'No Reviews',
    noActivePosts: 'No Active Posts to Show',
    newToOld: 'Newest to oldest',
    oldToNew: 'Oldest to Newest',
    lowToHigh: 'Lowest Rating to Highest Rating',
    highToLow: 'Highest Rating to Lowest Rating',
  },
  underConstructionPage: {
    title: `Oh! Sorry, this page is under construction.`,
    moreText:
      'In the meantime, you can download our app to enjoy all of Mazadat’s features',
  },
  downloadAppFooter: {
    title: 'Join now & explore Mazadat’s world',
    description: 'Download our app from here',
  },
  serviceWorker : {
    installedMessage : 'The app has been installed',
    updateMessage : 'There is a new update, please refresh'
  },
  countrySelect: {
    title: 'Country Select',
    apply: 'Apply',
  }
};
export default en;
