import axiosClient from './axiosClient';
const { client, injectStore } = axiosClient();

const HttpClient = function () {
    const middlewares = [];

    const applyMiddlewares = (initialRequest = {}) => {
        return middlewares.reduce((request, applyChanges) => {
            return request.then(applyChanges);
        }, Promise.resolve(initialRequest));
    };

    const queryString = (params = {}) => {
        const qs = Object.keys(params)
            .map((key) => {
                if (Array.isArray(params[key])) {
                    const value = params[key].reduce(
                        (prev, current, i) =>
                            `${prev}${key}=${params[key][i]}&`,
                        ''
                    );
                    return value.substring(0, value.length - 1);
                }
                return key + '=' + params[key];
            })
            .join('&');

        return qs.length > 0 ? '?' + qs : '';
    };

    const get = async (endpoint, params = {}, headers = {}) => {
        const requestParam = queryString(params);
        const requestHeaders = await applyMiddlewares(headers);
        return client.get(`${endpoint}${requestParam}`, requestHeaders);
    };

    const post = async (endpoint, body, headers = {}) => {
        const requestHeaders = await applyMiddlewares(headers);
        return client.post(endpoint, body, requestHeaders);
    };

  const put = async (endpoint, body, headers = {}) => {
    const requestHeaders = await applyMiddlewares(headers);
    return client.put(endpoint, body, requestHeaders);
  };

    const patch = async (endpoint, body, headers = {}) => {
        const requestHeaders = await applyMiddlewares(headers);
        return client.patch(endpoint, body, requestHeaders);
    };

    const _delete = async (endpoint, body, headers = {}) => {
        const requestHeaders = await applyMiddlewares(headers);
        return client.delete(endpoint, body, requestHeaders);
    };

    const addMiddleware = (middleware) => {
        middlewares.push(middleware);
    };

    return Object.freeze({
        get,
        post,
        put,
        patch,
        addMiddleware,
        delete: _delete,
    });
};

export { HttpClient, injectStore };
