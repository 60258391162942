/* eslint-disable max-lines */
const ar = {
  general: {
    ofText: 'من', //needs check from content team
    outOf: 'من',
    comma: '،',
    currency: 'ج.م',
  },
  language: {
    name: 'English',
  },
  pagination: {
    pageText: 'صفحة',
  },
  postActions: {
    done: 'تم!',
  },
  readMoreComponent: {
    showLess: 'إخفاء',
    readMore: 'قراءة المزيد',
  },
  postCardsSlider: {
    zeroState: 'لا توجد منتجات الآن',
    browseAll: 'تصفح الكل',
  },
  productConditions: {
    newText: 'جديد',
    mint: 'ممتاز',
    used: 'مستعمل',
    defective: 'به عيب',
    newWithOpenBox: 'جديد في علبة مفتوحة',
    newAndSealed: 'جديد في علبة مغلقة',
  },
  loginPage: {
    login: 'تسجيل دخول',
    loginTitle: (width, screenSize) => {
      if (width <= screenSize) {
        return 'أهلًا بعودتك!';
      } else {
        return 'بيع واشتري وزايد بسهولة وأمان';
      }
    },
    username: 'اسم المستخدم',
    usernamePlaceholder: 'أدخل اسم المستخدم الخاص بك',
    password: 'كلمة المرور',
    passwordPlaceholder: 'أدخل كلمة المرور',
    forgetPassword: 'نسيت كلمة المرور؟',
    signIn: 'تسجيل الدخول',
    dontAccount: 'ليس لديك حساب؟',
    phone: 'هاتف',
    phonePlaceholder: 'رقم الهاتف',
    loginByPhone: 'عن طريق رقم الهاتف',
    loginByUsername: 'بواسطة اسم المستخدم',
    signUp: 'سجل حساب',
  },
  userFeedback: {
    watchlist: {
      addedSuccessfully: 'تم إضافته إلى قائمة المتابعة بنجاح.',
      removedSuccessfully: 'تم إزالته من قائمة المتابعة بنجاح.',
    },
    httpErrorMsg: {
      connectionError: 'أنت لست متصلًا الآن',
      somethingWrong: 'يوجد خطأ، برجاء إعادة المحاولة',
    },
    categoriesPage: {
      addedSuccessfully: 'تم إضافته إلى الأقسام المفضلة بنجاح.',
      removedSuccessfully: 'تم إزالته من الأقسام المفضلة بنجاح.',
    },
    loginPage: {
      loginSuccessful: 'تم تسجيل الدخول بنجاح.',
      logoutSuccessfully: 'تم تسجيل الخروج بنجاح.',
    },
    searchForm: {
      historyCleared: 'History Cleared Successfully',
    },
    otpForm: {
      successSubmission: 'تم التسجيل بنجاح! احصل على التطبيق الآن.',
      whatsappCodeSentSucess: 'تم إرسال الرمز بنجاح إلى الواتس اب',
    },
    registrationForm: {
      recaptchaError: 'حدث خطأ أثناء التحقق من رمز reCAPTCHA، حاول مرة أخرى.',
    },
  },
  watchlistPage: {
    dayCount: (count) => {
      const number = count.toNumberFormat();
      return count > 1 ? `${number} أيام` : `${number} يوم`;
    },
    favouriteSP: 'نقطة الخدمة المفضلة',
    locationOnMaps: 'الموقع علي الخريطة',
    viewOnMap: 'عرض على خريطة جوجل',
    workingHours: 'مواعيد العمل',
    holiday: 'إجازة',
    watchlist: 'قائمة المتابعة',
  },

  forgetPasswordForm: {
    title: 'إعادة ضبط كلمة المرور',
    info: 'برجاء إدخال رقم الهاتف المحمول المسجل على التطبيق.',
    reset: 'إعادة ضبط',
  },

  resetPasswordForm: {
    title: 'قم بإنشاء كلمة مرور جديدة',
    info: 'يرجى اختيار كلمة مرور قوية تحتوي على أحرف وأرقام',
    newPassword: 'كلمة المرور الجديدة',
    newPasswordPlaceholder: 'كلمة المرور 8+ أحرف',
    confirmPassword: 'تأكيد كلمة المرور',
    confirmPasswordPlaceholder: 'أعد إدخال كلمة المرور الجديدة',
    resetPassword: 'إعادة ضبط كلمة المرور',
  },

  accountDropdown: {
    profile: 'الحساب',
    watchlist: 'قائمة المتابعة',
    notification: 'الإشعارات',
    logout: 'تسجيل الخروج',
    login: 'تسجيل الدخول',
    shoppingCart: 'سلة التسوق',
  },
  popUpsActions: {
    proceed: 'متابعة',
    cancel: 'الغاء',
  },
  legalPages: {
    top: 'لأعلى',
    topics: 'قائمة الموضوعات',
    productPolicy: 'سياسة المنتجات',
    businessRegistration: 'سياسة تسجيل حساب الأعمال',
    userAgreement: 'شروط الاستخدام',
    itemCondition: 'دليل حالات السلع ',
    feesPolicy: 'سياسة الرسوم',
    privacyPolicyTopics: {
      introduction: 'المقدمة والموافقة',
      topics: 'الموضوعات المُتناولة في سياسة الخصوصية هذه',
      collection: 'جمعنا لبياناتك الشخصية',
      purposes:
        'أغراص جمع مزادات لبياناتك الشخصية وتخزينها واستخدامها ومعالجتها',
      update: 'تحديث سياسة الخصوصية',
      personalInfo: 'الوصول إلى بياناتك الشخصية ومراجعتها وتعديلها',
      entities: 'الهيئات التي نشارك معها بياناتك الشخصية',
      marketing: 'التسويق والاتصال',
      cookies: 'ملفات تعريف الارتباط (الكوكيز)',
      protect: 'حماية معلوماتك الشخصية',
      general: 'الأحكام العامة',
    },
    termsAndConditionsTopics: {
      introduction: 'المقدمة والاتفاقية',
      service: 'الخدمة',
      fees: 'الرسوم',
      wallet: 'محفظة مزادات الخاصة بك',
      promotions: 'خدمة الإعلان مدفوع الأجر',
      conditions: 'شروط العرض/النشر على منصة مزادات واستخدامها',
      obligations: 'التزامات البائع',
      buyandbid: 'الشراء والمزايدة',
      collectionpoints: 'مراكز الخدمة (اضغط واستلم)',
      userRepresentations: 'إقرارات وضمانات المستخدم',
      registration: 'التسجيل وإنشاء الحساب',
      userViolations: 'انتهاكات المستخدم وإلغاء أو تعليق الحسابات',
      eligibility: 'أهلية العضوية وإبرام اتفاقية المستخدم',
      amendments: 'تعديل اتفاقية المستخدم هذه',
      governingLaw: 'القانون الحاكم',
      disputeResolutionForum: 'محل تسوية النزاعات',
      limitation: 'حدود المساءلة',
      warrantiesDisclaimer: 'إخلاء مسؤولية الضمانات',
      customerSupport: 'دعم العملاء الخاص بمزادات وتعليمات تقييم المستخدمين',
      indemnity: 'التعويض',
      daydeal: 'صفقة اليوم',
      licenses: 'التراخيص',
      intellectualPropertyRights:
        'حقوق الملكية الفكرية وبراءات الاختراع والعلامات التجارية',
      generalProvisions: 'الأحكام العامة',
    },
    productPolicyTopics: {
      introduction: 'المقدمة والموافقة',
      prohibitedProducts: 'المنتجات الممنوعة',
      restrictedProducts: 'المنتجات المقيدة',
      violation: 'انتهاك سياسة المنتجات الممنوعة والمنتجات المقيدة هذه',
    },
    businessRegistrationTopics: {
      registrationPolicy: 'سياسة تسجيل المستخدم التجاري',
      businessEntity: 'تسجيل الكيان التجاري الاعتباري',
    },
    conditionGuideTopics: {
      conditionGuide: 'دليل حالة السلع',
      categoriesAndDefinitions: 'الفئات المتاحة والتعريفات',
    },
    feesPolicyTopics: {
      feesPolicy: 'سياسة الرسوم',
      transactionFees: 'رسوم المعاملات',
      promotionsFees: 'رسوم الإعلان مدفوع الأجر',
      storageFees: 'رسوم التخزين',
    },
  },
  filters: {
    sortBy: 'ترتيب علي حسب',
    search: 'بحث',
    filter: 'منقي',
    result: 'نتيجة البحث',
    filterSearch: 'البحث في نتائج التصفية',
    resetAllFilter: 'إعادة ضبط الاختيارات',
    reset: 'إعادة ضبط',
    apply: 'تطبيق التغييرات',
    fromText: 'من',
    to: 'إلى',
  },

  postListPage: {
    price: 'السعر',
    category: 'القسم',
    pricing: 'تحديد السعر',
    zeroState: 'لا توجد نتيجة لإظهارها',
    noFilterResult: 'لا توجد نتائج مع التصفية المحددة',
    clearAll: 'مسح الكل',
    sellingMethod: 'طريقة البيع',
    sliderSectionTitle: 'المشاركات المميزة',
    filterResult: (count) => {
      const number = count.toNumberFormat();
      if (count === 1) {
        return `تم العثور على إعلان`;
      } else if (count === 2) {
        return `تم العثور على إعلانين`;
      } else if (count > 2 && count <= 10) {
        return `تم العثور على ${number} إعلانات`;
      } else if (count > 100) {
        return `تم العثور على 100+ إعلان`;
      } else return `${number} إعلان`;
    },
    filterChipsSeeMore: 'شاهد المزيد',
    filterChipsSeeLess: 'شاهد أقل',
    sorting: {
      lowToHigh: `السعر: من الأقل إلى الأعلى`,
      highToLow: `السعر: من الأعلى إلى الأقل`,
      newlyAdded: `تم إضافتها حديثًا`,
      sellerRating: `تقييم البائع`,
      endingSoonest: `ينتهي قريبًا`,
    },
  },
  searchPage: {
    zeroState: `لا توجد نتائج لبحثك`,
    resultFor: (keyword) => `نتيجة البحث عن ` + `"${keyword}"`,
  },
  searchResult: (count) => {
    const number = count.toNumberFormat();
    if (count === 1) {
      return `نتيجة`;
    } else if (count === 2) {
      return `نتيجتان`;
    } else if (count > 2 && count <= 10) {
      return `نتائج  ${number}`;
    } else if (count > 100) {
      return `نتيجة 100+`;
    } else return `${number} نتيجة`;
  },
  httpErrorMsg: {
    somethingWrong: 'يوجد خطأ، برجاء إعادة المحاولة',
    notFound: 'غير موجود',
    connectionError: 'أنت لست متصلًا الآن',
    unauthorized: 'غير مصرح به',
    noRefreshToken: 'لا يوجد رمز تحديث',
    pleaseTryAgain: 'حدث خطأ داخلي! برجاء المحاولة في وقت لاحق',
  },
  notFoundPage: {
    title: 'عفوًا ، لم يتم العثور على هذه الصفحة',
    description:
      'نحن آسفون حقًا ، لكن الصفحة التي طلبتها مفقودة .. ربما يمكننا مساعدتك أو العودة إلى الصفحة الرئيسية',
    backToHome: 'العودة إلى الصفحة الرئيسية',
    contactUs: 'اتصل بنا',
  },
  reportAbuseComponent: {
    reportAbuse: 'بلغ عن مشكلة',
  },
  createPostButton: {
    createAuctionTitle: 'إنشاء إعلان مزاد',
    createDirectSaleTitle: ' إنشاء إعلان بيع مباشر',
    createAuctionSubtitle: 'أعلى سعر ممكن',
    createDirectSaleSubtitle: 'أعرض بسعر ثابت',
    mazadatValetText: 'مزادات فاليه',
    mazadatValetLabel: 'جديد',
  },
  smallPostTimerComponent: {
    ends: 'تنتهي ',
    inText: 'في',
    expired: 'منتهي',
    timer3MinsRuleText: 'آخر فرصة!',
  },
  postDetailsPage: {
    productDescription: 'وصف المنتج',
    questionsAndAnswers: 'أسئلة وأجوبة',
    loadMore: 'تحميل المزيد',
    aboutSeller: 'عن البائع',
    securedBy: 'مؤمن بواسطة',
    dontMissOut: 'لا تفوت الفرصة!',
    willExpireIn: 'تنتهي بعد',
    days: 'أيام',
    hours: 'ساعات',
    minutes: 'دقائق',
    seconds: 'ثواني',
    material: 'الخامة',
    buyNow: 'اشتري الآن',
    bidNow: 'زايد الآن',
    buyFor: 'اشتري ب',
    youMadePurchase: 'قمت بشراء هذا المنتج في....',
    itemsAreRemaining: 'العناصر المتبقية',
    yourBidIsLeading: 'مزايدتك في المقدمة',
    noDescription: 'لا يوجد وصف للإعلان حتى الآن',
    noQuestionsAndAnswers: 'لا يوجد أسئلة وأجوبة حتى الآن',
    zeroState: 'لا يوجد تقييمات على هذا المنتج حتى الآن',
    loadMoreQuestions: 'عرض المزيد من الأسئلة',
    itemsSoon: 'أوشك على',
    soldOut: 'النفاذ',
    itemSoldOut: 'تم بيع المنتج',
    notSecured: 'تعامل مباشر',
    securedItemMobileView: 'مؤمن بواسطة مزادات',
    thisPostNotAvailable: 'هذا الإعلان غير متوفر',
    zoomImage: 'مرر المؤشر فوق الصورة لتكبيرها',
    pickupIs: 'الاستلام من نقطة الخدمة',
    deliverBy: 'التوصيل',
    pickupNotAvailable: 'غير متاح',
    pickupAvailable: 'متاح',
    mazadat: 'مزادات',
    reply: 'رد',
    maxQtyExceededText: 'تم بلوغ الحد الأقصى',
    differentCountryState: {
      title: 'عفوًا ، هذا المنشور غير متاح في بلدك',
      description: 'نحن آسفون حقًا ، لكن المنشور الذي تصفحته غير متاح في بلدك .. ربما يمكننا مساعدتك أو العودة إلى الصفحة الرئيسية',
    },
    brandInfoComponent: {
      category: 'القسم',
      subCategory: ' القسم الفرعي',
      warranty: 'ضمان',
      endTime: 'وقت الانتهاء',
    },
    actions: {
      postSimilarButton: 'نشر إعلان مماثل',
      shareButton: 'مشاركة',
    },
    bid: (number) => {
      const count = number.toNumberFormat();
      if (number === 1) {
        return `  مزايدة`;
      } else if (number === 2) {
        return `  مزايدتين`;
      } else if (number > 2 && number <= 10) {
        return `  ${count} مزايدات`;
      } else {
        return `${count} مزايدة`;
      }
    },
    viewAnswers: 'عرض الإجابات',
    reviewZeroState: 'لا يوجد تقييمات',
    viewReviews: 'إظهار التعليقات',
    tabToExpand: 'اضغط للعرض بشكل موسع',
    addReview: 'أضف تقييم',
    comments: 'التعليقات',
    totalComments: 'عدد التعليقات',
    viewReplies: (number) => {
      return `عرض ${number.toNumberFormat()} من التعليقات السابقة`;
    },
    hideReplies: 'إخفاء التعليقات',
    whatAboutThose: 'نرشح لك',
    cantFetchSection: `لا يمكن عرض محتويات هذا القسم`,
    backToHome: 'العودة إلى الصفحة الرئيسية',
    reportAProblem: 'الإبلاغ عن مشكلة',
    cancel: 'إلغاء',
    viewAllItems: 'عرض كل العناصر',
  },
  widgets: {
    securedItem: 'مؤمن بواسطة مزادات',
    securedItemTooltip : 'ابتعد عن مخاطر مقابلة الغرباء والمحتالين.',
    notSecured: 'تعامل مباشر',
    secure: 'مؤمن %100',
    payments: 'خيارات دفع متنوعة وآمنة',
    delivery: 'توصيل المنتجات',
    auctionOrDirectSale: 'مزايدة أو بيع مباشر',
    servicePointsNetwork: 'شبكة واسعة من نقاط الخدمة',
    winWinComissions: 'عمولة مشروطة بالبيع',
    digitalInvoice: 'نظام الفوترة الإلكترونية',
    activeItems: 'الأعلانات المفعلة',
    viewAllItems: 'عرض كل العناصر',
    secureDes:
      'تمتع بعمليات بيع وشراء آمنة وسهلة ومضمونة مع مزادات عبر نقاط خدمة متطورة تضمن سلامة كلًا من البائع والمشتري. فقط اختار مُؤَمّن بواسطة مزادات واترك الباقي علينا.',
    deliveryDes:
      'يمكنك استلام منتجاتك عبر خدمة التوصيل السريع من مزادات في أي وقت ومن أي مكان.',
    paymentsDes:
      'اختار طريقة الدفع المناسبة لك بكل أمان وسهولة إما دفع نقدي أو عبر بطاقات الائتمان أو محفظة مزادات الإلكترونية أو بالتقسيط.',
    auctionOrDirectSaleDes:
      'تمتع بأقصى قدر من الراحة واختار طريقة البيع المناسبة لاحتياجاتك: إما البيع عن طريق مزاد لأعلى سعر أو البيع بشكل مباشر عن طريق سعر محدد أو حتى الدمج بين الطريقتين.',
    servicePointsNetworkDes:
      'يمكنك معاينة المنتج قبل شراؤه بكل أمان عن طريق نقاط خدمة مزادات دون الحاجة لمقابلة الغرباء في أماكن مجهولة.',
    winWinComissionsDes:
      'نحصل على عمولتنا فقط عندما تكون الصفقة ناجحة! ببساطة اعرض منتجاتك بدون أي رسوم، وعندما تتم عملية البيع بنجاح، وقتها نقوم بتحصيل عمولتنا.',
    digitalInvoiceDes:
      'نظام آمن وبسيط لتسريع وتيرة عملك وتسهيل البيع أونلاين عبر إنشاء فواتير إلكترونية لجميع مبيعاتك، يمكنك الحصول عليها بسهولة في أي وقت ومن أي مكان.',
    learnMore: 'اقرأ المزيد',
  },
  biddingHistorySidebar: {
    viewHistory: 'عرض السجل',
    biddingHistory: 'تاريخ المزايدة',
    biddingPrice: 'سعر المزايدة:',
    bids: ' المزايدات',
    bidders: 'المزايدون',
    highestBid: 'أعلي مزايدة',
    noBidsAvailable: 'لا يوجد مزايدات  حتى الآن',
    beTheFirstToBid: 'لم يزايد أحد حتى الآن.. بادر بالمزايدة',
    lastBid: 'المزايدة الأخيرة',
    bidNow: 'زايد الآن',
    nextBid: 'المزايدة التالية',
  },
  header: {
    shopByCategory: 'تسوق حسب القسم',
    createPost: 'أنشئ إعلانك',
    becomeBusiness: 'حسابات الأعمال',
  },
  footer: {
    safePayments: 'يمكنك الدفع بأمان عبر',
    allRights: 'العلامة التجارية وجميع الحقوق محفوظة',
    mazadatInc: 'شركة مزادات © ، ',
    contactInfo: 'معلومات التواصل',
    customerService: 'خدمة العملاء',
    termsConditions: 'الشروط والأحكام',
    contactUs: 'تواصل معنا',
    privacyPolicy: 'سياسة الخصوصية',
    downloadApp: 'حمل تطبيقنا',
    mazadat: 'مزادات',
    aboutUs: 'عن مزادات',
    howITWorks: 'كيف تعمل مزادات؟',
    becomeBusiness: 'حسابات الأعمال',
  },
  footerMobile: {
    downloadApp: 'حمل تطبيق مزادات',
  },
  sharePopup: {
    shareThisPost: 'شارك الإعلان',
    copyLink: 'انسخ الرابط',
    copiedToClipboard: 'تم النسخ',
    copy: 'نسخ',
  },
  aboutSellerView: {
    verifiedSeller: 'بائع معتمد',
    viewProfile: 'عرض الملف الشخصي',
    totalRatingAsSeller: 'التقييم الكلي كبائع',
    showReviews: 'إظهار التقييمات',
    hideReviews: 'إخفاء التقييمات',
    reviewsIn: 'التقييمات اخر ',
    soldItems: (number) => {
      if (number > 1 && number <= 10) return `سلع منذ`;
      else if (number === 1 || number > 10) return `سلعة منذ`;
      else return 'عضو منذ';
    },
    sold: 'باع',
    outOf: 'من',
    chatWithSeller: 'تحدث مع البائع',
    loadMoreReview: 'مشاهدة المزيد',
    addReview: 'أضف تقييم',
    zeroState: 'لم تتلق أية تقييمات حتى الآن كبائع',
  },
  ratingView: {
    totalRatingText: 'التقييم الكلي',
    commitment: 'الالتزام',
    communication: 'التواصل',
    speedAndDelivery: 'سرعة التوصيل',
    overallExperience: 'تجربتك العامة',
    viewDetails: 'عرض تفاصيل السعر',
  },
  pagerButtonComponent: {
    // used in postCardSlider and postList
    next: 'التالي',
    previous: 'السابق',
  },
  postCard: {
    qtyText: (quantity, bids, discount) => {
      const number = quantity.toNumberFormat();
      // ORDER MATTERS, CHANGE CAREFULLY
      if (discount === 'amount') return '';
      // Discount = undefined or percentage
      if (bids > 0 || !!discount) {
        if (quantity === 0) return `متوفر ${number}`;
        if (quantity === 1) return 'متوفر سلعة';
        if (quantity === 2) return 'متوفر سلعتين';
        if (quantity >= 3 && quantity <= 10) return `${number} سلعات`;
        if (quantity > 10) return `${number} سلعة`;
      }
      // Bids = 0 and Discount = undefined
      if (quantity === 1) return `سلعة واحدة فقط متوفرة`;
      // Quantity is greater than 1
      if (quantity > 1) {
        if (quantity === 2) return `متوفر سلعتين فقط`;
        if (quantity >= 3 && quantity <= 10) return `${number} سلعات متوفرة`;
        if (quantity > 10) return `${number} سلعة متوفرة`;
      }
      // Quantity = 0
      if (quantity === 0) return 'المنتج غير متاح';
      return '';
    },
    bidsNumberText: (bids, discount) => {
      const number = bids.toNumberFormat();
      if (discount === 'amount') return '';
      if (bids === 1) return `مزايدة`;
      if (bids === 2) return `مزايدتين`;
      if (bids >= 3 && bids <= 10) return `${number} مزايدات`;
      if (bids > 10) return `${number} مزايدة`;
      return ``;
    },
    discountValuePrefix: 'وفر',
    off: 'خصم',
    bidNow: 'زايد الآن',
    buyNow: 'اشتري الآن',
    soldOut: 'نفذ',
    notAvailableViewComponent: {
      notAvailable: 'غير متوفر',
    },
  },
  homePage: {
    title: 'الصفحة الرئيسية على وشك الانطلاق قريبًا! انتظرونا 🚀',
    description: 'قم بتصفح باقي الأقسام واكتشف أفضل العروض والمزايدات ',
    featuredPostsTitle: 'إعلانات مميزة',
    topCategoriesTitle: 'أشهر الأقسام',
    startingFromValue: '100 جنيه',
    dayDeal: {
      title: 'عرض اليوم',
      dontMissOutWillExpiresIn: 'لا تفوت الفرصة! الإعلان ينتهي في ',
      discoverMore: 'اكتشف أكثر',
      // Todo
      qtyText: (value) => {
        if (value === 1) return `لم يتبق سوى عنصر واحد`;
        if (value > 1) return `${value.toNumberFormat()} العناصر المتبقية`;
        if (value === 0) return 'لا توجد عناصر متبقية';
        return '';
      },
    },
    comingSoon: {
      title: 'ترقب عروضنا اليومية!',
      weWillNotifyIn: 'سنرسل إشعارًا بعد',
      downloadAppGetLatest: 'حمل التطبيق وتابع عروضنا أول بأول',
    },
  },
  cookies: {
    siteCookies: 'مرحبًا بك! موقع مزادات يستخدم ملفات تعريف الارتباط (كوكيز)',
    cookiesDescription:
      '"تساعدنا ملفات التعريف في تذكر المعلومات الهامة عن زيارتك وتزويدك باقتراحات للسلع المناسبة لك، مما يسهل دخولك مرة أخرى ويجعلك تستفيد من الموقع بأفضل شكل ممكن.',
    acceptCookies: `اضغط هنا للموافقة`,
  },
  megaMenu: {
    close: 'إغلاق',
  },
  downloadPopup: {
    content: 'اسمح بتنزيل تطبيقنا لإستخدام هذه الخاصية',
    close: 'اغلق',
    downloadAppNow: 'حمل التطبيق الآن',
  },
  sendMessageForm: {
    title: 'إتصل بنا',
    description: `لأن التطوير يبدأ من خلالك، يمكنك إرسال مقترحاتك لجعل مزادات أفضل.`,
    fullName: 'الاسم الأول والأوسط والأخير',
    emailAddress: 'أدخل عنوان البريد الإلكتروني الخاص بك',
    emailAddressErr: 'البريد الإلكتروني',
    mobileNumber: 'أدخل رقم هاتفك المحمول',
    subject: 'أدخل موضوعك',
    message: 'الرجاء كتابة رسالتك',
    nameErr: 'الاسم',
    subjErr: 'عنوان الرسالة',
    mobileErr: 'رقم الهاتف المحمول',
    submitMessage: 'أرسل الرسالة',
    closeNow: 'غلق',
    thankYou: 'شكرا لك',
    characters: 'أحرف',
    successMessageDescription:
      'تم إرسال رسالتك وسيتم الرد عليها من  القسم المختص في أقرب فرصة ممكنة.',
  },
  contactUsPage: {
    weAreHere: {
      title: `تواصل معنا!`,
      description: `رأيك أو استفسارك مرحب به دائمًا في مزادات 💙`,
      headOffice: 'المكتب الرئيسي',
      nasrCity: 'مدينة نصر',
      nasrCityAddress: '17 مصطفى النحاس مدينة نصر، القاهرة',
      phoneNumber: 'رقم الهاتف',
      mobileNumber: 'رقم الهاتف المحمول',
    },
    ourServicePoints: {
      title: 'نقاط خدمتنا',
      description: 'أبوابنا دائمًا مفتوحة وفريقنا متواجد من أجلك',
      viewMap: 'إعرض الخريطة',
      locationOnMaps: 'الموقع علي الخريطة',
      workingHours: 'مواعيد العمل',
      holiday: 'إجازة',
      viewOnMap: 'عرض على خريطة جوجل',
    },
    //Todo: needs check from content team.
    contactFromHeart: {
      title: 'تابعونا الآن لمعرفة كل جديد.',
      description: `تابعونا على`,
    },
  },
  getHelpComponent: {
    // used in contact us , terms & conditions and privacy policy
    title: `تحتاج إلى المزيد من المساعدة؟`,
    shareExcitement: 'شاركنا تجربتك على مزادات',
    sendInquiry: 'أو أرسل لنا استفسارك',
  },
  textFieldComponent: {
    characters: 'أحرف',
    optionalLabel: 'اختياري',
    password: 'كلمة المرور',
  },
  phoneNumberComponent: {
    phoneNumber: 'رقم الهاتف',
    code: 'رمز',
    selectCode: 'اختر رمز',
  },
  registrationForm: {
    joinUs: 'انضم إلينا',
    byContinuing: (width, screenSize) => {
      if (width <= screenSize) {
        return 'بالمتابعة ، أنت توافق على مزايا';
      } else {
        return 'بالمتابعة ، فإنك توافق على ';
      }
    },
    termsOfService: (width, screenSize) => {
      if (width <= screenSize) {
        return 'شروط الخدمة';
      } else {
        return 'شروط الاستخدام ';
      }
    },
    registeredBusiness: 'الأعمال المسجلة',
    invitationCode: 'كود الدعوة',
    verify: 'تحقق',
    phoneNumber: 'رقم الهاتف',
    code: 'رمز',
    username: 'اسم المستخدم',
    enterUsername: 'أدخل اسم المستخدم الخاص بك',
    password: 'كلمة المرور',
    passwordCharacters: 'ادخل رقمك السري',
    title: (width, screenSize) => {
      if (width <= screenSize) {
        return 'هيا لنبدأ';
      } else {
        return 'إذا لم تدق الفرصة ، فقم ببناء باب.';
      }
    },
    successSubmission: 'تم التسجيل بنجاح! احصل على التطبيق الآن.',
    businessTooltip:
      'قم بتوسيع أعمالك و أرباحك من خلال التسجيل بحساب الأعمال لتحصل على مزايا استثنائية!',
    invitationCodeTooltip: 'أدخل كود الدعوة الذي حصلت عليه من أحد أصدقائك.',
    userNameCheckList: {
      usernameCharLength: 'من 3 إلى 30 حرفًا (أحرف أو أرقام).',
      noSpecialChar: 'بدون أحرف خاصة في البداية أو النهاية.',
      noSpaces: 'بدون مسافات.',
      noUpperCase: 'بدون أحرف كبيرة.',
      lessThanSix: '5 أرقام كحد أقصى.',
    },
    invitationCodePlaceHolder: 'أدخل رمز الدعوة',
    signUp: 'سجل حساب',
    signIn: 'تسجيل الدخول',
    charAnd: ' و ',
    codeError: 'اختر رمز البلد',
    selectCode: 'اختر رمز',
    alreadyAccount: 'هل لديك حساب؟',
    privacyPolicy: 'سياسة الخصوصية',
  },
  validationErrorMsg: {
    required: (name) => `${name} مطلوب`,
    min: (name, min) => `${name} يجب ان يكون علي الأقل ${min} احرف`,
    max: (name, max) => `${name} يجب ان يكون علي الأكثر ${max} احرف`,
    invalid: (name) => `${name} غير صالح!`,
    nameError: 'لا يمكن قبول اسم المستخدم، يرجى التحقق من قواعد التسجيل',
    confirmPassword: 'كلمة المرور غير متطابقة',
  },
  otpForm: {
    title: 'قم بتأكيد رقم هاتفك المحمول',
    enterTheCode: 'قم بإدخال الكود',
    changeNumber: 'تغيير الرقم',
    notReceiveCode: 'لم تتلق الرمز؟',
    resendIn: 'أعد الإرسال',
    resendButton: 'إعادة إرسال',
    verify: 'تحقق',
    enterTheCodeSentToEmail: 'أدخل الرمز المرسل إلى بريدك الإلكتروني',
    changeEmail: 'تغيير البريد الإلكتروني',
    otpInfo: 'أدخل الرمز المكون من 4 أرقام الذي تم إرساله إلى هاتفك المحمول:',
    whatsapp: 'أو عبر الواتساب',
    successSubmission:
      'تم تسجيل حسابك! يمكنك تحميل الأبلكيشن حتى تستفيد من جميع مميزات مزادات.',
  },
  passwordStrength: {
    weakPassword: 'كلمة مرور ضعيفة',
    averagePassword: 'كلمه مرور متوسطة',
    greatPassword: 'كلمة مرور رائعة',
  },
  breadCrumb: {
    home: 'الصفحه الرئيسيه',
    editPost: 'تعديل الإعلان',
    similarPost: 'إعلان مماثل',
    sharePost: 'شارك الإعلان',
    addToWatchList: 'اضافة إلى قائمتك المفضلة',
    reportAbuse: 'بلغ عن مشكلة',
    privacyPolicy: 'سياسة الاستخدام',
    termsAndConditions: 'الشروط والأحكام',
    productPolicy: 'سياسة المنتجات',
    businessRegistrationPolicy: 'سياسة تسجيل حساب الأعمال',
    feesPolicy: 'سياسة الرسوم',
    aboutUs: 'عن مزادات',
    howItWorks: 'كيف تعمل مزادات؟',
    categories: 'الأقسام',
    sellerProfile: 'حساب البائع',
    comingSoon: 'قريباً',
    notFound: 'غير موجود',
    contactUs: 'اتصل بنا',
  },
  bottomMenu: {
    profile: 'ملف المستخدم',
    search: 'بحث',
    categories: 'الأقسام',
    home: 'الصفحة الرئيسية',
  },
  searchView: {
    searchFieldPlaceHolder: `ابحث عن...`,
  },
  aboutUsPage: {
    /** *will change it when the content will be ready  */
    shoppingExperience: {
      title:
        'نفتح للمستخدمين طرقاً عديدة للبيع والشراء عبر تجربة مبتكرة وفريدة من نوعها',
      description: `مزادات هي المنصة الأولى للمزادات في إفريقيا والوطن العربي التي تقدم تجربة تسوق ومزايدة فريدة لجميع مستخدميها سواء الأفراد و الشركات لبيع وشراء المنتجات بأكثر من حالة من خلالها عن طريق مزاد محدد زمنيًا أو عن طريق سعر ثابت أو مزج الطريقتين.`,
      scroll: 'لأسفل',
    },
    innerSections: {
      onlineAndOffline: 'نبني جسور بين  عالمي التسوق',
      onlineAndOfflineDesc: `في مزادات نسعى إلى توفير مجموعة متنوعة من المنتجات بسعر تناف+سي وفي نفس الوقت نو+ سهولة أكثر في تجربة البيع أو الشراء مع التزامنا بمعايير الأمان والسلامة حيث أن التعامل بين المستخدمين يتم من خلال نقاط الخدمة المنتشرة في القاهرة والجيزة لفحص المنتجات قبل إتمام الشراء دون الكشف عن هوية المستخدمين أو بياناتهم الشخصية، كما أننا نوفر فرص أكثر لأصحاب المشاريع الصغيرة والمتوسطة لتوسيع نطاق أعمالهم من خلال الوصول لمستخدمين أكثر وتوفيرنا للدفع بطرق مختلفة مع إمكانية التقسيط لكل المنتجات حتى المستعملة منها.`,
      innovation: `بناء قنوات مبتكرة ومستدامة تخدم الجميع`,
      innovationDesc: `في مزادات، نطمح إلى بناء مجتمع يخدم الجميع من خلال تجارب التجارة الإلكترونية المتطورة: حيث يمكن للجميع النمو والازدهار. نحن مؤمنون بشدة بأن الجهود الصغيرة يمكن أن تحدث فارقًا كبيرًا، ولذلك، نحن ملتزمون منذ بداية تأسيس مزادات بتعزيز فكرة إعادة بيع المنتجات التي سبق امتلاكها سواءًا كانت جديدة أو مستعملة، وتشجيع المشترين والبائعين على تمديد عمر المنتجات للمساهمة في الحفاظ على بيئة نظيفة واقتصاد مستدام.`,
      safety: `توفير الراحة والسلامة للمستخدمين`,
      safetyDesc: `في مزادات نسعى إلى توفير مجموعة متنوعة من المنتجات بسعر تنافسي وفي نفس الوقت نوفر سهولة أكثر في تجربة البيع أو الشراء مع التزامنا بمعايير الأمان والسلامة حيث أن التعامل بين المستخدمين يتم من خلال نقاط الخدمة المنتشرة في القاهرة والجيزة لفحص المنتجات قبل إتمام الشراء دون الكشف عن هوية المستخدمين أو بياناتهم الشخصية.كما أننا نوفر فرص أكثر لأصحاب المشاريع الصغيرة والمتوسطة من خلال خدماتنا لمساعدتهم في توسيع نطاق أعمالهم  والوصول لمستخدمين أكثر. وتوفيرنا للدفع بطرق مختلفة مع إمكانية التقسيط لكل المنتجات حتى المستعملة منها.`,
      safetyDescSub: `يأتي ذلك مع تقديم مجموعة من حلول الدفع المتنوعة، سواء الدفع النقدي "كاش" أو عبر بوابات الدفع الإلكتروني والمحافظ الهاتفية، أو من خلال خطط دفع آجل "تقسيط" ميسرة ومرنة وطويلة الأجل.`,
    },
    mazadatValues: {
      sectionTitle: `قيم مزادات `,
      firstSlide: {
        title: 'الابتكار',
        desc: 'يوفر نموذج الأعمال الذي ابتكرناه لمستخدمينا تجربة تجارية فريدة، حيث يستخدم تطبيق مزادات أحدث التكنولوجيا في تنسيق تجربة تسوق خالية من المتاعب.',
      },
      secondSlide: {
        title: 'الأمان',
        desc: 'تولي منصتنا اهتماما كاملاً بالأمان والسلامة وبناء الثقة في عالم يفتقر إلى الثقة، بالإضافة إلى توفير خدمة الضمان المالي، التي تضمن الرضا للمشتري والبائع على حد سواء.',
      },
      thirdSlide: {
        title: 'الشمولية',
        desc: 'يمكن الوصول لمنصتنا بكل سهولة من قبل مستخدمينا، بغض النظر عن اللغة المستخدمة، الموقع الجغرافي أو أسلوب الحياة المتبع. تنعكس الشمولية في منصتنا في الهيكل المتعدد للغات والخيارات المتعددة للدفع، وخيارات الشحن المرنة لتلبية الاحتياجات المتنوعة.',
      },
      fourthSlide: {
        title: 'الاستدامة',
        desc: 'منصتنا تعزز فكرة إعادة التجارة؛ وذلك بتشجيع المشترين والبائعين على تمديد عمر الأغراض المملوكة مسبقًا. وهذا يساهم في الحد من النفايات والحفاظ على الموارد.',
      },
      fifthSlide: {
        title: 'التعاون',
        desc: 'نتطلع إلى بناء مجتمع يخدم الجميع من خلال تجربة تجارة إلكترونية قابلة للتطوير. سواء كنت طالب، أو ربة منزل، أو بائع يسعى إلى توسيع نطاق أعماله، فإننا نرحب بك للانضمام إلى مجتمعنا والتعاون معنا.',
      },
    },
    ourServicesPoint: {
      title: `نقاط خدمتنا`,
      servicePoints: {
        heliopolis: 'هليوبوليس',
        el_Mohandseen: `المهندسين`,
        nasrCity: 'مدينة نصر',
        maadi: 'المعادي',
      },
    },
    subscription: {
      title: `يسعدنا أن نعرف المزيد عن شركتك`,
    },
  },
  howItWorksPage: {
    header: {
      smartAndSafe: 'ذكي. آمن.',
      convenient: 'مريح',
      bidBuyAndSell: 'زايد وبيع واشتري',
      description: 'بحساب واحد بيع واشتري وزايد بأمان',
    },
    fiveProducts: {
      title: '5 حالات مختلفة للمنتجات',
      newText: 'جديد',
      mint: 'ممتاز',
      used: 'مستعمل',
      defective: 'به عيب',
      openBox: 'مستعمل في علبة مفتوحة',
    },
    checkOutMazadat: {
      title: `أكثر من 35 قسم للتسوق والبيع  من خلالهم`,
    },
    forgetAboutScam: {
      title: 'مع خدمة مؤمن بواسطة مزادات عاين واستلم منتجك بأمان',
      description:
        'نقاط خدمتنا منتشرة في أكثر من مكان لتعاين وتستلم منتجك بأمان وخصوصية دون الحاجة لمقابلة الغرباء في الأماكن العامة والتعرض لأي نوع من المضايقات.',
    },
    payment: {
      title: `طرق دفع متنوعة وآمنة`,
    },
    mazadatInNumbers: {
      title: `مزادات بالأرقام`,
      circles: {
        download: 'ثواني لتحميل الأبلكيشن',
        auctions: 'ثانية لعمل مزاد أو إعلان بسعر محدد',
        dropOff: 'ساعة لتسليم أو استلام منتجك',
        returnRequest: 'ساعة لتصفح منتجك وعمل طلب إرجاع',
        sellerBuyer: 'حماية البائع والمشتري',
        conditions: 'حالات مختلفة للمنتجات',
        increasePosts: 'باقات ترويجية تختار منها لزيادة فرصة بيع منتجك',
      },
    },
    whySecured: {
      cards: {
        pickup: 'التسليم والاستلام الآمن في نقاط الخدمة.',
        inspect: 'معاينة المنتج  قبل الاستلام.',
        deliveryService: 'خدمات تخزين وتوصيل المنتجات.',
        returnRequest: ' إمكانية الاسترجاع خلال 48 ساعة من الاستلام',
      },
      title: 'لماذا "مؤمن بواسطة مزادات"؟',
    },
    shopSmart: {
      title: 'تجربة تسوق مميزة',
    },
  },
  categoriesPage: {
    favouriteCategories: 'الأقسام المفضلة',
    allCategories: 'جميع الأقسام',
    searchPlaceholder: 'البحث في الأقسام',
    noFavouriteSelected: 'اضغط على أيقونة القلب لإضافة القسم للأقسام المفضلة',
  },
  sellerProfilePage: {
    showContact: 'إظهار جهة الاتصال',
    shareProfile: 'مشاركة الملف الشخصي',
    reviews: 'التقييمات',
    activePost: 'إعلان نشط',
    sellerReviews: 'التقييم الكامل',
    outOf: 'من',
    reportAbuse: 'بلغ عن مشكلة',
    viewReviews: 'عرض التقييم كاملًا',
    zeroState: 'لا يوجد تقييمات على هذا البائع حتى الآن',
    noActivePosts: 'لا توجد إعلانات نشطة',
    newToOld: 'من الأحدث إلى الأقدم',
    oldToNew: 'من الأقدم إلى الأحدث',
    lowToHigh: 'من أدنى إلى أعلى تقييم',
    highToLow: 'من أعلى إلى أدنى تقييم',
  },
  underConstructionPage: {
    title: 'سيتم إطلاق هذه الصفحة قريباً!',
    moreText:
      'يمكنك البحث عن المنتجات أو تصفحها من القائمة مباشرة في الوقت الحالي',
  },
  downloadAppFooter: {
    title: 'انضم الآن واكتشف عالم مزادات!',
    description: 'حمل التطبيق من هنا',
  },
  serviceWorker : {
    installedMessage : 'تم تثبيت التطبيق',//needs a content review
    updateMessage : 'هناك تحديث جديد،يرجي إعاده تحميل الصفحه'//needs a content review
  },
  countrySelect: {
    title: 'اختر بلد',
    apply: 'تطبيق',
  }
};

export default ar;
