/**
 * @credibility:
 * https://gist.github.com/ulisesantana/8bb0d6b02e675e6fb98480f06c9e0183#file-localstorage-service-js
 */
const StorageService = {
    get: (itemName) => {
        const item = localStorage.getItem(itemName);
        const numPatt = new RegExp(/^\d+$/);
        // eslint-disable-next-line no-useless-escape
        const jsonPatt = new RegExp(/[\[\{].*[\}\]]/);

        if (item) {
            if (jsonPatt.test(item)) {
                return JSON.parse(item);
            } else if (numPatt.test(item)) {
                return parseFloat(item);
            } else {
                return item;
            }
        } else {
            return null;
        }
    },

    set: (itemName, item) => {
        if (typeof item === 'object') {
            localStorage.setItem(itemName, JSON.stringify(item));
        } else {
            localStorage.setItem(itemName, item);
        }
    },

    remove: (itemName) => {
        localStorage.removeItem(itemName);
    },
};


export default StorageService;