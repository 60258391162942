const AccountDropdownItem = ({ icon, title, onClick }) => {
  return (
    <div onClick={onClick} className="accountDropdown-item">
      <div className="accountDropdown-item_wrapper">
        <span className={icon}></span>
        <span className={'accountDropdown-item_wrapper_title'}>{title}</span>
      </div>
    </div>
  );
};

export default AccountDropdownItem;
