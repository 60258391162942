import { useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { useScreenWidth } from '../../../hooks/useScreenWidth';
import { useQuery } from '../../../hooks/useQuery';
import { useLocation } from 'react-router-dom';
import { translate } from '../../../app/i18n';
import { IconAssets } from '../../../assets/assetProvider';
import { languageToSwitch } from '../../../app/helper/general';

function LanguageSwitcher({ active, className }) {
  const [isLoading, setIsLoading] = useState(false);
  const screenWidth = useScreenWidth();
  const searchQuery = useQuery();
  const { pathname } = useLocation();

  const updateLanguage = (lang) => {
    setIsLoading(true);
    searchQuery.set('lang', lang);
    location.assign(`${pathname}?${searchQuery.toString()}`);
  };

  return (
    <>
      {isLoading ? (
        <Loader
          type="TailSpin"
          className={`${className && className}`}
          width={21}
          height={21}
          color="#00b0b8"
          secondaryColor="#1A4083"
        />
      ) : (
        <div className={`language-switcher ${className && className}`}>
          <button
            className={'language-switcher_wrapper'}
            onClick={() => {
              updateLanguage(languageToSwitch(active));
            }}
          >
            {screenWidth > 912 ? (
              <span className="language-switcher_wrapper_title">
                {translate('language.name')}
              </span>
            ) : (
              <img
                loading="lazy"
                className="language-switcher_wrapper_img"
                src={IconAssets.flagsSwitcherAssets[active]}
                alt="langFlag"
              />
            )}
          </button>
        </div>
      )}
    </>
  );
}

LanguageSwitcher.propTypes = {
  id: PropTypes.string,
  active: PropTypes.string,
};

export default LanguageSwitcher;
