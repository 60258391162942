import { translate } from '../../app/i18n';
import services from '../../services';
import { snackboxOpen } from './snackboxAction';
import {
  fetchUserDataFailed,
  fetchUserDataSuccess,
  fetchUserSearchHistorySuccess,
  loading,
} from '../slices/userSlice';

export const fetchUserData = () => async (dispatch) => {
  try {
    dispatch(loading());
    const data = await services.userService.getUserData();
    dispatch(fetchUserDataSuccess(data));
  } catch ({ message }) {
    dispatch(fetchUserDataFailed({ error: message }));
  }
};

export const updateUserLanguage = (body) => async (dispatch) => {
  if (body.id) {
    try {
      await services.userService.updateUserLanguage(body);
      dispatch(fetchUserData());
    } catch ({ message }) {
      dispatch(snackboxOpen(message, 'failure'));
    }
  }
};

export const updateUserCountry = (body) => async (dispatch) => {
    try {
     const res = await services.userService.updateUserCountry(body);
     dispatch(snackboxOpen(res?.message, 'success'));

    } catch ({ message }) {
      dispatch(snackboxOpen(message, 'failure'));
    }
  
};

export const fetchUserSearchHistory = () => async (dispatch) => {
  try {
    const res = await services.userService.getSearchHistory();
    dispatch(fetchUserSearchHistorySuccess(res.searchHistory));
  } catch ({ message }) {
    dispatch(snackboxOpen(message, 'failure'));
  }
};

export const postUserSearchHistory = (keyword) => async (dispatch) => {
  try {
    await services.userService.postSearchHistory(keyword);
    dispatch(fetchUserSearchHistory());
  } catch ({ message }) {
    dispatch(snackboxOpen(message, 'failure'));
  }
};

export const deleteSearchHistory = () => async (dispatch) => {
  try {
    dispatch(loading());
    await services.userService.deleteSearchHistory();
    dispatch(fetchUserSearchHistory());
    dispatch(
      snackboxOpen(
        translate('userFeedback.searchForm.historyCleared'),
        'success',
      ),
    );
  } catch ({ message }) {
    dispatch(snackboxOpen(message, 'failure'));
  }
};
