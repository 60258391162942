import { initializeApp } from 'firebase/app';
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from 'firebase/remote-config';
import { getAnalytics } from 'firebase/analytics';
import config from '../../config';

const app = initializeApp(config.FIREBASE);
const remoteConfig = getRemoteConfig(app);

const defaultObj = {
  website_assets: {
    home: {
      topBanner: {
        imagesUrl: {
          img_2560_en:
            config.IMG_CONTAINER_WITHOUT_ENV +
            'landing-page/top_banner-2560x600_en.png',
          img_1920_en:
            config.IMG_CONTAINER_WITHOUT_ENV +
            'landing-page/top_banner-1920x480_en.png',
          img_1366_en:
            config.IMG_CONTAINER_WITHOUT_ENV +
            'landing-page/top_banner-1366x480_en.png',
          img_1024_en:
            config.IMG_CONTAINER_WITHOUT_ENV +
            'landing-page/top_banner-1024x420_en.png',
          img_768_en:
            config.IMG_CONTAINER_WITHOUT_ENV +
            'landing-page/top_banner-768x420_en.png',
          img_640_en:
            config.IMG_CONTAINER_WITHOUT_ENV +
            'landing-page/top_banner-640x320_en.png',
          img_360_en:
            config.IMG_CONTAINER_WITHOUT_ENV +
            'landing-page/top_banner-360x320_en.png',
          img_2560_ar:
            config.IMG_CONTAINER_WITHOUT_ENV +
            'landing-page/top_banner-2560x600_ar.png',
          img_1920_ar:
            config.IMG_CONTAINER_WITHOUT_ENV +
            'landing-page/top_banner-1920x480_ar.png',
          img_1366_ar:
            config.IMG_CONTAINER_WITHOUT_ENV +
            'landing-page/top_banner-1366x480_ar.png',
          img_1024_ar:
            config.IMG_CONTAINER_WITHOUT_ENV +
            'landing-page/top_banner-1024x420_ar.png',
          img_768_ar:
            config.IMG_CONTAINER_WITHOUT_ENV +
            'landing-page/top_banner-768x420_ar.png',
          img_640_ar:
            config.IMG_CONTAINER_WITHOUT_ENV +
            'landing-page/top_banner-640x320_ar.png',
          img_360_ar:
            config.IMG_CONTAINER_WITHOUT_ENV +
            'landing-page/top_banner-360x320_ar.png',
        },
        routeUrl: '',
      },
      comingSoon: {
        imagesUrl: {
          ar:
            config.IMG_CONTAINER_WITHOUT_ENV +
            'landing-page/coming-soon-AR-2.png',
          en: config.IMG_CONTAINER_WITHOUT_ENV + 'landing-page/daydeal_en.png',
        },
        routeUrl: '',
      },
    },
    postDetails: {
      sideBanner: {
        imagesUrl: {
          en:
            config.IMG_CONTAINER_WITHOUT_ENV + 'landing-page/sideBanner_en.png',
          ar:
            config.IMG_CONTAINER_WITHOUT_ENV + 'landing-page/sideBanner_ar.png',
        },
        routeUrl: '',
      },
    },
    // {TODO}
    // "aboutUs": {
    //   "servicePoints": {
    //     "imagesUrl": {
    //       "point_1_1140": "المهندسين.png",
    //       "point_1_320": "Mohandsen.jpg",
    //       "point_2_1140": "مصر الجديده.png",
    //       "point_2_320": "heliopolis.jpg",
    //       "point_3_1140": "مدينه نصر.png",
    //       "point_3_320": "nasr city.jpg",
    //     }
    //   },
    //   "videoBanner": {
    //     "videosUrl": {
    //       "point_1_1140": "about us .mp4",
    //       "point_1_360": "About us.mp4",
    //     }
    //   }
    // },
    footer: {
      imagesUrl: {
        img_2560_en:
          config.IMG_CONTAINER_WITHOUT_ENV +
          'landing-page/footer_banner-2560x480_en.png',
        img_1920_en:
          config.IMG_CONTAINER_WITHOUT_ENV +
          'landing-page/footer_banner-1920x400_en.png',
        img_1366_en:
          config.IMG_CONTAINER_WITHOUT_ENV +
          'landing-page/footer_banner-1366x400_en.png',
        img_1024_en:
          config.IMG_CONTAINER_WITHOUT_ENV +
          'landing-page/footer_banner-1024x380_en.png',
        img_768_en:
          config.IMG_CONTAINER_WITHOUT_ENV +
          'landing-page/footer_banner-768x360_en.png',
        img_640_en:
          config.IMG_CONTAINER_WITHOUT_ENV +
          'landing-page/footer_banner-640x320_en.png',
        img_360_en:
          config.IMG_CONTAINER_WITHOUT_ENV +
          'landing-page/footer_banner-360x280_en.png',
        img_2560_ar:
          config.IMG_CONTAINER_WITHOUT_ENV +
          'landing-page/footer_banner-2560x480_ar.png',
        img_1920_ar:
          config.IMG_CONTAINER_WITHOUT_ENV +
          'landing-page/footer_banner-1920x400_ar.png',
        img_1366_ar:
          config.IMG_CONTAINER_WITHOUT_ENV +
          'landing-page/footer_banner-1366x400_ar.png',
        img_1024_ar:
          config.IMG_CONTAINER_WITHOUT_ENV +
          'landing-page/footer_banner-1024x380_ar.png',
        img_768_ar:
          config.IMG_CONTAINER_WITHOUT_ENV +
          'landing-page/footer_banner-768x360_ar.png',
        img_640_ar:
          config.IMG_CONTAINER_WITHOUT_ENV +
          'landing-page/footer_banner-640x320_ar.png',
        img_360_ar:
          config.IMG_CONTAINER_WITHOUT_ENV +
          'landing-page/footer_banner-360x280_ar.png',
      },
      routeUrl: '',
    },
  },
  country_codes: {
    countries: [
      {
        countryName: {
          en: 'Egypt',
          ar: 'مصر',
        },
        countryCallCode: '+20',
        countryISOCode: 'EG',
        countryFlag: 'https://mezo.azureedge.net/flags/EG.svg',
      },
      {
        countryName: {
            en: "Saudi Arabia",
            ar: "السعودية"
        },
        countryCallCode: "+966",
        countryISOCode: "SA",
        countryFlag: "https://firebasestorage.googleapis.com/v0/b/mazadat-firebase.appspot.com/o/flags%2FEG.svg?alt=media"
    },
    {
      countryName: {
          en: "United Arab Emirates",
          ar: "الإمارات"
      },
      countryCallCode: "+971",
      countryISOCode: "AE",
      countryFlag: "https://firebasestorage.googleapis.com/v0/b/mazadat-firebase.appspot.com/o/flags%2FEG.svg?alt=media"
  },
  {
    countryName: {
        en: "Oman",
        ar: "عمان"
    },
    countryCallCode: "+968",
    countryISOCode: "OM",
    countryFlag: "https://firebasestorage.googleapis.com/v0/b/mazadat-firebase.appspot.com/o/flags%2FEG.svg?alt=media"
}
    ],
  },
  ABTester: false,
};

remoteConfig.defaultConfig = {
  website_assets: JSON.stringify(defaultObj.website_assets),
  country_codes: JSON.stringify(defaultObj.country_codes),
  ABTester: JSON.stringify(defaultObj.ABTester),
};

/**
 * Get config values
 * @param {'website_assets' | 'country_codes' | 'ABTester' | 'homepage_banner' | 'share_text'} key - value key
 * @returns {Value}
 */
export const getConfigValue = (key) => {
  return getValue(remoteConfig, key);
};

/**
 * Get config values from Json
 * @param {'website_assets' | 'country_codes' | 'ABTester' | 'homepage_banner' | 'share_text'} key - value key
 * @returns {*}
 */
export const getJsonConfig = (key) => {
  let config = '';
  try {
    config = getValue(remoteConfig, key).asString();
  } catch (err) {
    //   Pass
  }
  return JSON.parse(config) || {};
};

export const analytics = getAnalytics(app);
export async function getRemoteConfigAsync(key) {
  const getValue = async (delay = 0) => {
    await new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
    const data = getConfigValue(key);
    if (data.getSource() === 'remote') return data;
    return await getValue(1);
  };
  return await getValue();
}

const fetchActivateFirebase = () =>
  fetchAndActivate(remoteConfig)
    .then(() => {
      //   Pass
    })
    .catch(() => {
      // Pass
    });

export default fetchActivateFirebase;
