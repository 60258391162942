import * as yup from 'yup';
import { translate } from '../i18n';
import { isValidPhoneNumber, sanitizeIntlPhoneNumber } from './custom-validations';

/**
 * Todo: We'll need to refactor this validation file to be more dynamic by returning a function with parameters
 * @example Example If a field like phone number can be required or notRequired on different forms but same validation
 */
const name = yup
  .string()
  .trim()
  .required(
    translate('validationErrorMsg.required')(
      translate('sendMessageForm.nameErr'),
    ),
  )
  .test(
    'nameRequired',
    translate('validationErrorMsg.invalid')(
      translate('sendMessageForm.nameErr'),
    ),
    (value) =>
      !value ||
      value.match(
        /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/,
      ),
  );
const email = yup
  .string()
  .trim()
  .required(
    translate('validationErrorMsg.required')(
      translate('sendMessageForm.emailAddressErr'),
    ),
  )
  .email(
    translate('validationErrorMsg.invalid')(
      translate('sendMessageForm.emailAddressErr'),
    ),
  )
  .min(
    5,
    translate('validationErrorMsg.min')(
      translate('sendMessageForm.emailAddress'),
      5,
    ),
  )
  .max(
    255,
    translate('validationErrorMsg.max')(
      translate('sendMessageForm.emailAddress'),
      255,
    ),
  );
const subject = yup
  .string()
  .min(
    5,
    translate('validationErrorMsg.min')(
      translate('sendMessageForm.subjErr'),
      5,
    ),
  )
  .trim()
  .required(
    translate('validationErrorMsg.required')(
      translate('sendMessageForm.subjErr'),
    ),
  )
  .test(
    'subjectRequired',
    translate('validationErrorMsg.invalid')(
      translate('sendMessageForm.subjErr'),
    ),
    (value) => !value || !value.match(/^[0-9]/),
  );
const phone = yup
  .string()
  .trim()
  .required(
    translate('validationErrorMsg.required')(
      translate('sendMessageForm.mobileErr'),
    ),
  )
  .test({
    test: (value, { parent }) => {
      const isPhoneValid = isValidPhoneNumber(
        parent.code + parent.phone
      );
      return !value.includes('+') && isPhoneValid;
    },
    message: translate('validationErrorMsg.invalid')(
      translate('sendMessageForm.mobileErr'),
    ),
  });
const intPhone = yup
  .string()
  .trim()
  .max(
    15,
    translate('validationErrorMsg.max')(
      translate('sendMessageForm.mobileErr'),
      15,
    ),
  )
  .matches(/^(\+)?\d+$/, {
    message: translate('validationErrorMsg.invalid')(
      translate('sendMessageForm.mobileErr'),
    ),
    excludeEmptyString: true,
  })
  .test({
    test: (value) => {
      if (value) {
        const phoneNumber = sanitizeIntlPhoneNumber(
          value
        );
        return isValidPhoneNumber(phoneNumber);
      }
      return true;
    },
    message: translate('validationErrorMsg.invalid')(
      translate('sendMessageForm.mobileErr'),
    ),
  });
const message = yup
  .string()
  .trim()
  .required(
    translate('validationErrorMsg.required')(
      translate('sendMessageForm.message'),
    ),
  )
  .min(
    5,
    translate('validationErrorMsg.min')(
      translate('sendMessageForm.message'),
      5,
    ),
  );
const notsearch_password = yup
  .string()
  .required(
    translate('validationErrorMsg.required')(
      translate('textFieldComponent.password'),
    ),
  )
  .min(
    8,
    translate('validationErrorMsg.min')(
      translate('textFieldComponent.password'),
      8,
    ),
  );
const confirmPassword = yup
  .string()
  .required(
    translate('validationErrorMsg.required')(
      translate('resetPasswordForm.confirmPassword'),
    ),
  )
  .oneOf(
    [yup.ref('notsearch_password')],
    translate('validationErrorMsg.confirmPassword'),
  );
const otp = yup
  .string()
  .required(
    translate('validationErrorMsg.required')(
      translate('registrationForm.code'),
    ),
  )
  .length(
    4,
    translate('validationErrorMsg.min')(translate('registrationForm.code'), 4),
  );
const username = yup
  .string()
  .trim()
  .required(
    translate('validationErrorMsg.required')(
      translate('registrationForm.username'),
    ),
  )
  .test({
    test: (value, context) => {
      const errors = [
        {
          text: translate(
            'registrationForm.userNameCheckList.usernameCharLength',
          ),
          validation(username) {
            return username?.length >= 3 && username?.length <= 30;
          },
        },
        {
          text: translate('registrationForm.userNameCheckList.noSpecialChar'),
          validation(username) {
            return (
              username &&
              /^[a-zA-Z\u0621-\u064A]([._-]?[a-zA-Z\u0621-\u064A0-9\u0660-\u0669]+)*$/.test(
                username,
              )
            );
          },
        },
        {
          text: translate('registrationForm.userNameCheckList.noSpaces'),
          validation(username) {
            return username && !/\s/.test(username);
          },
        },
        {
          text: translate('registrationForm.userNameCheckList.noUpperCase'),
          validation(username) {
            return username && !/(?=.*[A-Z])/.test(username);
          },
        },
        {
          text: translate('registrationForm.userNameCheckList.lessThanSix'),
          validation(username) {
            return (
              username && /^(?!(.*[0-9\u0660-\u0669].*){6}).*$/.test(username)
            );
          },
        },
      ].filter(({ validation }) => !validation(value));

      if (errors.length < 1) {
        return true;
      }
      return context.createError({ message: errors[0].text });
    },
  });

export {
  name,
  email,
  phone,
  subject,
  message,
  intPhone,
  notsearch_password,
  username,
  confirmPassword,
  otp,
};
