import { API } from '../constants';

export default function RerurnService(httpClient) {

    const getAllReturns = (queryObject) => {
        return httpClient.get(API.GET_RETURNS, queryObject);
    };

    const fetchReturnFilters = () => {
        return httpClient.get(API.GET_RETURN_FILTERS);
    };

    const fetchReturnDetails = (id) => {
        return httpClient.get(`${API.GET_RETURN_DETAILS}${id}`);
    }

    const fetchReturnHistoryStatus= (id) => {
        return httpClient.get(`${API.GET_RETURN_STATUS_HISTORY}${id}`);
    }

    return { getAllReturns, fetchReturnFilters , fetchReturnDetails , fetchReturnHistoryStatus };
}
