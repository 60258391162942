import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, MazadatLogo } from '../../../atoms';
import { LanguageSwitcher } from '../../../molecules';
import { translate } from '../../../../app/i18n';
import config from '../../../../config';

const AuthHeader = () => {
  const { websiteLanguage } = useSelector((state) => state.user);
  const [showLangMenu] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  function handleWindowResize() {
    setWindowSize(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize, { passive: true });
    if (windowSize <= config.SMALL_SCREEN) {
      // setMobileView(true);
    } else {
      // setMobileView(false);
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  const BusinessButton = () => {
    return (
      <Button color="primary-bordered" onClick={() => window.open(config.B2C)}>
        {translate('header.becomeBusiness')}
      </Button>
    );
  };

  return (
    <>
      {
        <div className="authHeaderContainer">
          <div className="authHeaderContainer__black-logo">
            <MazadatLogo />
          </div>
          <div className="authHeaderContainer__authButtons">
            <BusinessButton />
            <div className="authHeaderContainer__authButtons__languageButton">
              <LanguageSwitcher active={websiteLanguage} />
            </div>
          </div>
        </div>
      }

      {showLangMenu && <div className="cardProducts-overlay"></div>}
    </>
  );
};
export default AuthHeader;
