import { Fragment } from 'react';
import DayDeal from '../../pages/newHome/dayDeal/dayDeal';
import { HomeSections } from '../../pages/newHome/homeDynamicSections/home-sections';
import { useHomePostsQuery } from '../../../hooks/usePostQuery';
import { existDynamicSections } from '../../../app/helper/general';

export default function DynamicSections() {
  const { data } = useHomePostsQuery();

  const filteredData = existDynamicSections(data);
  return (
    <>
      {filteredData?.map((el, index) => {
        return (
          <Fragment key={index}>
            {el.key === 'dayDeals' ? (
              <DayDeal url={el.url} />
            ) : (
              <HomeSections
                url={el.url}
                sectionObj={el}
                index={index}
                title={el.headline}
                wrapperColor={index % 2 === 1 ? 'blue' : false}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
}
