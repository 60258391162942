
export const FS_6 = '6px';
export const FS_10 = '10px';
export const FS_12 = '12px';
export const FS_13 = '13px';
export const FS_14 = '14px';
export const FS_16 = '16px';
export const FS_18 = '18px';
export const FS_20 = '20px';
export const FS_21 = '21px';
export const FS_22 = '22px';
export const FS_24 = '24px';
export const FS_30 = '30px';
export const FS_32 = '32px';
export const FS_36 = '36px';
export const FS_38 = '38px';
export const FS_40 = '40px';
export const FS_42 = '42px';
export const FS_48 = '48px';
export const FS_60 = '60px';
export const FS_80 = '80px';
export const FS_120 = '120px';

