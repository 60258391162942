import { API } from '../constants';

export default function OrderService(httpClient) {
    const getAllOrders = (queryObject) => {
        return httpClient.get(API.GET_ORDERS, queryObject);
    };

    const fetchOrderFilters = () => {
        return httpClient.get(API.GET_ORDER_FILTERS);
    };

    const getOrderDetails = (id) => {
        return httpClient.get(`${API.GET_ORDER_DETAILS}${id}`);
    };

    return { getAllOrders, fetchOrderFilters, getOrderDetails };
}
